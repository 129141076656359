import React, { useState, forwardRef, useRef } from "react";
import ReactDatetime from "react-datetime";

import { optionsDropdown } from '../../assets/js/options.js';
import { ViewType, StateActionsLiterals } from '../../assets/js/stateActionListerals'
import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Label,
    FormText,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    UncontrolledAlert
} from "reactstrap";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-labels'

import { Bar, Pie } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

import background from "../../assets/img/header-background.jpg";
import InstanceHeader from "components/Headers/InstanceHeader.js";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import 'antd/dist/antd.css';
import { Cascader } from 'antd';
import { CommonLoading } from 'react-loadingg';
import GenericHeader from "components/Headers/GenericHeader.js";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { strictEqual } from "react-entities";
import { Accordion } from "react-bootstrap";

import { ApiCallerHelper } from "../../api/apiCallerHelper"
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

const colors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(128, 0, 128, 0.6)',
    'rgba(0, 0, 255, 0.6)',
    'rgba(0, 128, 0, 0.6)',
    'rgba(0, 128, 128, 0.6)',
    'rgba(128, 0, 0, 0.6)',
    'rgba(0, 0, 128, 0.6)',
    'rgba(255, 0, 255, 0.6)',
    'rgba(128, 128, 0, 0.6)',
    'rgba(255, 0, 0, 0.6)',
    'rgba(255, 165, 0, 0.6)',
    'rgba(106, 90, 205, 0.6)',
    'rgba(255, 99, 71, 0.6)',
    'rgba(145, 53, 118, 0.6)',
    'rgba(60, 179, 113, 0.6)',
    'rgba(69, 89, 90, 0.6)',
    'rgba(25, 56, 20, 0.6)',
    'rgba(180, 79, 13, 0.6)'
];

// const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//   ],
// };

class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: ''
        };

        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    }

    handleChangeStartDate(e) {
        this.props.updateStartDateProp(e);
        this.setState({ startDate: e })
    }

    handleChangeEndDate(e) {
        this.props.updateEndDateProp(e);
        this.setState({ endDate: e })
    }


    render() {
        return (
            <>
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                    inputProps={{
                                        placeholder: "Από"
                                    }}
                                    timeFormat={false}
                                    renderDay={(props, currentDate, selectedDate) => {
                                        let classes = props.className;
                                        if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            this.state.startDate._d + "" === currentDate._d + ""
                                        ) {
                                            classes += " start-date";
                                        } else if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            new Date(this.state.startDate._d + "") <
                                            new Date(currentDate._d + "") &&
                                            new Date(this.state.endDate._d + "") >
                                            new Date(currentDate._d + "")
                                        ) {
                                            classes += " middle-date";
                                        } else if (
                                            this.state.endDate &&
                                            this.state.endDate._d + "" === currentDate._d + ""
                                        ) {
                                            classes += " end-date";
                                        }
                                        return (
                                            <td {...props} className={classes}>
                                                {currentDate.date()}
                                            </td>
                                        );
                                    }}
                                    onChange={this.handleChangeStartDate}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                    inputProps={{
                                        placeholder: "Έως"
                                    }}
                                    timeFormat={false}
                                    renderDay={(props, currentDate, selectedDate) => {
                                        let classes = props.className;
                                        if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            this.state.startDate._d + "" === currentDate._d + ""
                                        ) {
                                            classes += " start-date";
                                        } else if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            new Date(this.state.startDate._d + "") <
                                            new Date(currentDate._d + "") &&
                                            new Date(this.state.endDate._d + "") >
                                            new Date(currentDate._d + "")
                                        ) {
                                            classes += " middle-date";
                                        } else if (
                                            this.state.endDate &&
                                            this.state.endDate._d + "" === currentDate._d + ""
                                        ) {
                                            classes += " end-date";
                                        }
                                        return (
                                            <td {...props} className={classes}>
                                                {currentDate.date()}
                                            </td>
                                        );
                                    }}
                                    onChange={this.handleChangeEndDate}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }
}

class ParameterStatistics extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayModes: {
                receiverKind: 'value',
                receiverName: 'value',
                instanceCategory: 'value',
                energyKind: 'value',
                instanceStep: 'value',
                senderAccept: 'value',
                pendingInstances: 'value',
                responseTime: 'value',
                InTime: 'value',
            },
            loading: false,
            startDate: '-',
            endDate: '-',
            gasMaintainers: [],
            electrMaintainers: [],
            gasSuppliers: [],
            electrSuppliers: [],
            gasAndElectrSuppliers: [],
            maintainers: [],
            suppliers: [],
            allSuppliers: [],
            energyKind: 'ΟΛΑ',
            receiverKind: 'ΟΛΑ',
            receiverName: 'ΟΛΑ',
            receiverName2: 'ΟΛΑ',
            instanceStatus: 'ΟΛΑ',
            instanceStep: 'ΟΛΑ',
            instanceCategory: 'ΟΛΑ',
            instanceSubject: 'ΟΛΑ',
            senderAccept: 'ΟΛΑ',
            InTime: 'ΟΛΑ',
            pendingInstances: 'ΟΛΑ',
            responseTime: 'ΟΛΑ',
            instanceCategoryandSubject: 'ΟΛΑ',
            declinedBy: 'ΟΛΑ',
            isPendingChecked: false,
            chartData: {
                receiverKind: {},
                receiverName: {},
                instanceCategory: {},
                energyKind: {},
                instanceStatus: {},
                instanceStep: {},
                senderAccept: {},
                pendingInstances: {},
                responseTime: {},
                InTime: {},
            },
            chartType: {
                receiverKind: 1,
                receiverName: 2,
                instanceCategory: 1,
                energyKind: 2,
                instanceStatus: 1,
                instanceStep: 1,
                senderAccept: 2,
                pendingInstances: 1,
                responseTime: 2,
                InTime: 2
            },
            showCharts: 0,
            loadingCharts: false,
            zippedReceiverKind: [],
            zippedReceiverName: [],
            zippedInstanceCategory: [],
            zippedInstanceStatus: [],
            zippedInstanceStep: [],
            zippedEnergyKind: [],
            zippedSenderAccept: [],
            zippedPendingInstances: [],
            zippedResponseTime: [],
            zippedDeclinedBy: [],
            zippedInTime: [],
            sum: null,
            sumAcceptance: null,
            sumResponseTime: null,
            sumPendingInstances: null,
            SumInTime: null,
            sumInstanceStatus: null,
            sumDeclinedBy: null,
            isOpenDanger: false,
            user: {},
            isAdmin: true,
            dynamicCategoryOptions: StateActionsLiterals.GenerateOptions(ViewType.rae, 'ΟΛΑ', 'ΟΛΑ')
        }
        this.receiverKindRef = React.createRef();
        this.receiverNameRef = React.createRef();
        this.instanceCategoryRef = React.createRef();
        this.energyKindRef = React.createRef();
        this.instanceStatusRef = React.createRef();
        this.instanceStepRef = React.createRef();
        this.senderAcceptRef = React.createRef();
        this.pendingInstancesRef = React.createRef();
        this.responseTimeRef = React.createRef();
        this.declinedByRef = React.createRef();
        this.InTimeRef = React.createRef();

        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
        this.changeEnergyKind = this.changeEnergyKind.bind(this);
        this.changeReceiverKind = this.changeReceiverKind.bind(this);
        this.changeReceiverName = this.changeReceiverName.bind(this);
        this.changeReceiverName2 = this.changeReceiverName2.bind(this);
        this.changeInstanceStatus = this.changeInstanceStatus.bind(this);
        this.changeInstanceStep = this.changeInstanceStep.bind(this);
        this.changeInstanceCategory = this.changeInstanceCategory.bind(this);
        this.changeInstanceSubject = this.changeInstanceSubject.bind(this);
        this.changeSenderAccept = this.changeSenderAccept.bind(this);
        this.changePendingInstances = this.changePendingInstances.bind(this);
        this.changeResponseTime = this.changeResponseTime.bind(this);
        this.changeChartType = this.changeChartType.bind(this);
        this.changeDataType = this.changeDataType.bind(this);
        this.saveFilters = this.saveFilters.bind(this);
        this.onDismissDanger = this.onDismissDanger.bind(this);
        this.changeDeclinedBy = this.changeDeclinedBy.bind(this);
        this.changeInTime = this.changeInTime.bind(this);
        this.togglePendingCheck = this.togglePendingCheck.bind(this);
        // this.deleteFile = this.deleteFile.bind(this);

        // this.updateInputValueCascader = this.updateInputValueCascader.bind(this);


        //this.dynamicOptions = StateActionsLiterals.GenerateOptions(ViewType.rae);
    }

    toggleDisplayMode = (chartKey) => {
        this.setState(prevState => {
            let updatedMode = prevState.displayModes[chartKey] === 'value' ? 'percentage' : 'value';
            return {
                displayModes: {
                    ...prevState.displayModes,
                    [chartKey]: updatedMode
                }
            };
        });
    };

    getOptionsForBarChart = (chartKey) => {
        return {
            responsive: true,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true  // This ensures that the y-axis starts from 0
                    }
                }]
            },
            plugins: {
                legend: {
                    position: 'top'
                },
                title: {
                    display: true,
                    text: 'Chart.js Bar Chart',
                },
                labels: {
                    render: (args) => {
                        if (this.state.displayModes[chartKey] === 'percentage') {
                            const total = args.dataset.data.reduce((a, b) => a + b, 0);
                            const percentage = Math.round(args.value / total * 100);
                            return percentage + '%';
                        } else {
                            return args.value;
                        }
                    },
                    fontStyle: 'bold',
                    position: 'outside',
                    textMargin: 6
                },
            },
        };
    };




    getOptionsForPieChart = (chartKey) => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top'
                },
                title: {
                    display: true,
                    text: 'Chart.js Pie Chart',
                },
                labels: {
                    render: (args) => {
                        if (this.state.displayModes[chartKey] === 'percentage') {
                            const total = args.dataset.data.reduce((a, b) => a + b, 0);
                            const percentage = Math.round(args.value / total * 100);
                            return percentage + '%';
                        } else {
                            return args.value;
                        }
                    },
                    fontStyle: 'bold',
                    position: 'outside',
                    textMargin: 6
                },
            },
        };
    };



    getDynamicOptionLiteralCategory = (valueKey) => {
        let result = this.state.dynamicCategoryOptions.filter(x => x.value == valueKey.trim())[0] ?? null;
        if (result == null) {
            return valueKey;
        }
        return result.label;
    }
    getDynamicOptionLiteralSubCategory = (valueKey) => {
        let result = this.state.dynamicCategoryOptions.filter(x => x.children.filter(y => y.value == valueKey.trim()).length > 0)[0] ?? null;
        if (result == null) {
            return valueKey;
        }
        return result.children.filter(x => x.value == valueKey.trim())[0].label;
    }

    downloadAllForAdmin() {
        [
            { a: "Στατιστικά ανά είδος Παραλήπτη.jpeg", b: this.receiverKindRef },
            { a: "Στατιστικά ανά Χρήστη.jpeg", b: this.receiverNameRef },
            { a: "Στατιστικά ανά Κατηγορία Αιτήματος.jpeg", b: this.instanceCategoryRef },
            { a: "Στατιστικά ανά είδος Ενέργειας.jpeg", b: this.energyKindRef },
            // { a: "Στατιστικά ανά Κατάσταση Παραπόνου.jpeg", b: this.instanceStatusRef },
            { a: "Στατιστικά ανά Αποδοχή Απάντησης.jpeg", b: this.senderAcceptRef },
            ...(this.state.isPendingChecked ? [{ a: "Στατιστικά ανά Μέσο Χρόνο Εκκρεμότητας.jpeg", b: this.pendingInstancesRef }] : []),
            { a: "Στατιστικά ανά Χρονικό Διάστημα πρώτης Απάντησης.jpeg", b: this.responseTimeRef },
            // { a: "Στατιστικά Επεμβάσεων ΡΑΕ.jpeg", b: this.declinedByRef },
            { a: "Στατιστικά Χρόνων Απόκρισης Ενεργών Αναφορών.jpeg", b: this.InTimeRef },
            { a: "Στατιστικά Αναφορών ανά Βήμα Αίτησης.jpeg", b: this.instanceStepRef },

        ].forEach(element => {
            const link = document.createElement('a');
            link.download = element.a;
            link.href = element.b.current.chartInstance.toBase64Image('image.jpeg', 1);
            link.click();
        });
    }

    downloadAllForUser() {
        [
            { a: "Στατιστικά ανά είδος Παραλήπτη.jpeg", b: this.receiverKindRef },
            { a: "Στατιστικά ανά Κατηγορία Αιτήματος.jpeg", b: this.instanceCategoryRef },
            { a: "Στατιστικά ανά είδος Ενέργειας.jpeg", b: this.energyKindRef },
            // { a: "Στατιστικά ανά Κατάσταση Παραπόνου.jpeg", b: this.instanceStatusRef },
            { a: "Στατιστικά ανά Αποδοχή Απάντησης.jpeg", b: this.senderAcceptRef },
            ...(this.state.isPendingChecked ? [{ a: "Στατιστικά ανά Μέσο Χρόνο Εκκρεμότητας.jpeg", b: this.pendingInstancesRef }] : []),
            { a: "Στατιστικά ανά Χρονικό Διάστημα πρώτης Απάντησης.jpeg", b: this.responseTimeRef },
            // { a: "Στατιστικά Επεμβάσεων ΡΑΕ.jpeg", b: this.declinedByRef },
            { a: "Στατιστικά Αναφορών ανά Βήμα Αίτησης.jpeg", b: this.instanceStepRef },
            { a: "Στατιστικά Χρόνων Απόκρισης Ενεργών Αναφορών.jpeg", b: this.InTimeRef },

        ].forEach(element => {
            const link = document.createElement('a');
            link.download = element.a;
            link.href = element.b.current.chartInstance.toBase64Image('image.jpeg', 1);
            link.click();
        });
    }

    downloadReceiverKind() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά είδος Παραλήπτη.jpeg';
        link.href = this.receiverKindRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    downloadReceiverName() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά Χρήστη.jpeg';
        link.href = this.receiverNameRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    downloadInstanceCategory() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά Κατηγορία Αιτήματος.jpeg';
        link.href = this.instanceCategoryRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    downloadEnergyKind() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά είδος Ενέργειας.jpeg';
        link.href = this.energyKindRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    // downloadInstanceStatus() {
    //     const link = document.createElement('a');
    //     link.download = 'Στατιστικά ανά Κατάσταση Παραπόνου.jpeg';
    //     link.href = this.instanceStatusRef.current.chartInstance.toBase64Image('image.jpeg', 1);
    //     link.click();
    // }
    downloadInstanceStep() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά Βήμα Αίτησης.jpeg';
        link.href = this.instanceStepRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    downloadSenderAccept() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά Αποδοχή Απάντησης.jpeg';
        link.href = this.senderAcceptRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    downloadPendingInstances() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά Μέσο Χρόνο Εκκρεμότητας.jpeg';
        link.href = this.pendingInstancesRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    downloadResponseTime() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά ανά Χρονικό Διάστημα πρώτης Απάντησης.jpeg';
        link.href = this.responseTimeRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }
    // downloadDeclinedBy() {
    //     const link = document.createElement('a');
    //     link.download = 'Στατιστικά Επεμβάσεων ΡΑΕ.jpeg';
    //     link.href = this.declinedByRef.current.chartInstance.toBase64Image('image.jpeg', 1);
    //     link.click();
    // }
    downloadInTime() {
        const link = document.createElement('a');
        link.download = 'Στατιστικά Χρόνων Απόκρισης Ενεργών Αναφορών.jpeg.jpeg';
        link.href = this.InTimeRef.current.chartInstance.toBase64Image('image.jpeg', 1);
        link.click();
    }

    onDismissDanger = () => {
        this.setState({ isOpenDanger: false });
        this.setState({
            startDate: '-',
            endDate: '-',
            showCharts: 0,
            energyKind: 'ΟΛΑ',
            receiverKind: 'ΟΛΑ',
            selectedSupplierMaintainer: '-',
            selectedComplaint: '-',
            receiverName: 'ΟΛΑ',
            receiverName2: 'ΟΛΑ',
            instanceStatus: 'ΟΛΑ',
            instanceStep: 'ΟΛΑ',
            instanceCategory: 'ΟΛΑ',
            instanceSubject: 'ΟΛΑ',
            senderAccept: 'ΟΛΑ',
            pendingInstances: 'ΟΛΑ',
            responseTime: 'ΟΛΑ',
            instanceCategoryandSubject: 'ΟΛΑ',
            declinedBy: 'ΟΛΑ',
            InTime: 'ΟΛΑ',
            isPendingChecked: false,
        });
    }

    updateStartDate = (startdate) => {
        try {

            this.setState({ startDate: startdate.toDate().toLocaleString('en-CA').split(',')[0] });
        }
        catch
        {
            this.setState({ startDate: '-' });
        }
    }

    updateEndDate = (enddate) => {
        try {

            let endDatetoDate = enddate.toDate();
            const addOneDay = new Date(new Date(endDatetoDate).setDate(endDatetoDate.getDate() + 1))
            this.setState({ endDate: addOneDay.toLocaleString('en-CA').split(',')[0] });
        }
        catch
        {
            this.setState({ endDate: '-' });
        }
    }

    changeReceiverKind = (receiverKind) => {
        let value = receiverKind['value'];

        this.setState(
            {
                receiverKind: value,
                dynamicCategoryOptions: StateActionsLiterals.GenerateOptions(ViewType.rae, value, this.state.energyKind)
            }, () =>
            console.log(`Receiver selected:`, this.state.receiverKind)
        );

    };

    changeEnergyKind = (energyKind) => {
        this.state.energyKind = energyKind['value'];
        if (this.state.energyKind == 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο') {
            this.state.receiverKind = 'Προμηθευτής Ενέργειας'
            console.log(`Receiver selected:`, this.state.receiverKind)
        }
        this.setState(
            {
                energyKind: this.state.energyKind,
                receiverKind: this.state.receiverKind,
                dynamicCategoryOptions: StateActionsLiterals.GenerateOptions(ViewType.rae, this.state.receiverKind, this.state.energyKind)
            }, () =>
            console.log(`Type selected:`, this.state.energyKind)

        );
    };

    changeReceiverName = (receiverName) => {
        this.setState({ receiverName: receiverName['value'] }, () =>
            console.log(`Receiver Name selected:`, this.state.receiverName)
        );
    }

    changeReceiverName2 = (receiverName2) => {
        this.setState({ receiverName2: receiverName2['value'] }, () =>
            console.log(`Receiver Name selected:`, this.state.receiverName2)
        );
    }

    changeInstanceStatus = (event) => {
        this.setState({ instanceStatus: event.target.value }, () =>
            console.log(`Instance Status selected:`, this.state.instanceStatus)
        );
    }

    changeInstanceStep = (event) => {
        if (event.target.value == '') {
            this.setState({ instanceStep: 'ΟΛΑ' })
            console.log(`Instance Step selected:`, this.state.instanceStep)
        }
        else {
            this.setState({ instanceStep: event.target.value }, () =>
                console.log(`Instance Step selected:`, this.state.instanceStep)
            );
        }
    }

    changeInstanceCategory = (event) => {
        this.setState({ instanceCategory: event.target.value, instanceSubject: 'ΟΛΑ' }, () =>
            console.log(`Instance Category selected:`, this.state.instanceCategory)
        );
    }

    changeInstanceSubject = (event) => {
        this.setState({ instanceSubject: event.target.value }, () =>
            console.log(`Instance Subject selected:`, this.state.instanceSubject)
        );

    }

    changeSenderAccept = (event) => {
        this.setState({ senderAccept: event.target.value }, () =>
            console.log(`Sender Accept selected:`, this.state.senderAccept)
        );
    }

    changePendingInstances = (event) => {
        this.setState({ pendingInstances: event.target.value }, () =>
            console.log(`Pending Instances selected:`, this.state.pendingInstances)
        );
    }

    togglePendingCheck() {
        this.setState(prevState => ({ isPendingChecked: !prevState.isPendingChecked }));
    }

    changeResponseTime = (event) => {
        this.setState({ responseTime: event.target.value }, () =>
            console.log(`Response Time selected:`, this.state.responseTime)
        );
    }

    changeDeclinedBy = (event) => {
        this.setState({ declinedBy: event.target.value }, () =>
            console.log(`Declined by selected:`, this.state.declinedBy)
        );
    }

    changeInTime = (event) => {
        this.setState({ InTime: event.target.value }, () =>
            console.log(`InTime selected:`, this.state.InTime)
        );
    }

    changeInstanceCategoryANDSubject = (event) => {
        this.setState({ instanceCategory: event[0], instanceSubject: event[1] }, () =>
            console.log(this.state.instanceCategory + this.state.instanceSubject)
        );
    }

    // changeInstanceCategoryANDSubject = (event) => {
    //     if (event[0] == 'ΟΛΑ') {
    //         this.setState({ instanceCategory: 'OΛΑ', instanceSubject: 'ΟΛΑ' })
    //         console.log(`Instance Status selected:`, this.state.instanceStatus)
    //     }
    //     else {
    //         this.setState({ instanceCategory: event[0], instanceSubject: event[1] }, () =>
    //             console.log(`Instance Status selected:`, this.state.instanceStatus)
    //         );
    //     }
    // }

    changeChartType = (event) => {
        this.setState({ chartType: event.target.value }, () =>
            console.log(`Chart Type selected:`, this.state.chartType)
        );
    }

    changeDataType = (event) => {
        this.setState({ dataType: event.target.value }, () =>
            console.log(`Data Type selected:`, this.state.dataType)
        );
    }

    updateAnswer(evt) {

        this.setState({
            answer: evt.target.value
        })
    }

    changeType(event) {
        this.setState({ selectedType: event.target.value });

        if (event.target.value == 3) {
            this.setState({ selectedReceiver: 2 });
        }

        if (event.target.value == 4) {
            this.state.submitButton = 1;
        }

    }

    reloadFilters(evt) {
        this.setState({
            startDate: '-',
            endDate: '-',
            showCharts: 0,
            energyKind: 'ΟΛΑ',
            receiverKind: 'ΟΛΑ',
            selectedSupplierMaintainer: '-',
            selectedComplaint: '-',
            receiverName: 'ΟΛΑ',
            receiverName2: 'ΟΛΑ',
            instanceStatus: 'ΟΛΑ',
            instanceStep: 'ΟΛΑ',
            instanceCategory: 'ΟΛΑ',
            instanceSubject: 'ΟΛΑ',
            senderAccept: 'ΟΛΑ',
            pendingInstances: 'ΟΛΑ',
            responseTime: 'ΟΛΑ',
            declinedBy: 'ΟΛΑ',
            InTime: 'ΟΛΑ',
            instanceCategoryandSubject: 'ΟΛΑ'
        });
    }

    switchType(chart, choice) {
        let tempType = this.state.chartType;

        if (chart == 1) {
            tempType.receiverKind = choice
            this.setState({
                chartType: tempType
            })
        }
        else if (chart == 2) {
            tempType.receiverName = choice
            this.setState({
                chartType: tempType
            })
        }
        else if (chart == 3) {
            tempType.instanceCategory = choice
            this.setState({
                chartType: tempType
            })
        }
        else if (chart == 4) {
            tempType.energyKind = choice
            this.setState({
                chartType: tempType
            })
        }
        // else if (chart == 5) {
        //     tempType.instanceStatus = choice
        //     this.setState({
        //         chartType: tempType
        //     })
        // }
        else if (chart == 5) {
            tempType.instanceStep = choice
            this.setState({
                chartType: tempType
            })
        }
        else if (chart == 6) {
            tempType.senderAccept = choice
            this.setState({
                chartType: tempType
            })
        }
        else if (chart == 7) {
            tempType.pendingInstances = choice
            this.setState({
                chartType: tempType
            })
        }
        // else if (chart == 9) {
        //     tempType.declinedBy = choice
        //     this.setState({
        //         chartType: tempType
        //     })
        // }
        else if (chart == 8) {
            tempType.responseTime = choice
            this.setState({
                chartType: tempType
            })
        }
        else if (chart == 9) {
            tempType.InTime = choice
            this.setState({
                chartType: tempType
            })
        }
    }

    saveFilters = async () => {
        this.setState({ loadingCharts: true })
        let result = await ApiCallerHelper.Statistics.statsFilter({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            receiverKind: this.state.receiverKind,
            receiverName: (this.state.user && Object.keys(this.state.user).length === 0) ? this.state.receiverName : this.state.user['Name'],
            // 'instanceStatus': this.state.instanceStatus,
            instanceStep: this.state.instanceStep,
            energyKind: this.state.energyKind,
            instanceCategory: this.state.instanceCategory,
            instanceSubject: this.state.instanceSubject,
            senderAccept: this.state.senderAccept,
            pendingInstances: this.state.pendingInstances,
            responseTime: this.state.responseTime,
            // 'declinedBy': this.state.declinedBy,
            InTime: this.state.InTime,
            isPendingChecked: this.state.isPendingChecked
        });

        if (result[0] == -1) {
            this.setState({
                isOpenDanger: true,
                loadingCharts: false
            });
        }
        else {
            this.setState({
                sum: result.Sum,
                sumAcceptance: result.SumAcceptance,
                sumPendingInstances: result.SumPendingDuration,
                sumResponseTime: result.SumResponseTime,
                SumInTime: result.SumInTime
            })
            //update labels
            //subcategories
            //replace values return from api
            for (let i = 0; i < result.InstanceCategories.labels.length; i++) {
                let label = result.InstanceCategories.labels[i].trim();
                result.InstanceCategories.labels[i] = this.getDynamicOptionLiteralSubCategory(label)
                let label2 = result.InstanceCategories.labels[i].trim();
                result.InstanceCategories.labels[i] = this.getDynamicOptionLiteralCategory(label2)
            }


            this.setState(prevState => ({
                chartData: {
                    receiverKind: {
                        labels: result.ReceiverKinds.labels,
                        datasets: [
                            {
                                label: 'Ανά Είδος Παραλήπτη',
                                data: result.ReceiverKinds.data,
                                backgroundColor: colors
                            },
                        ],
                    },
                    receiverName: {
                        labels: result.ReceiverNames.labels,
                        datasets: [
                            {
                                label: 'Ανά Χρήστη',
                                data: result.ReceiverNames.data,
                                backgroundColor: colors
                            },
                        ],
                    },
                    instanceCategory: {
                        labels: result.InstanceCategories.labels,
                        datasets: [
                            {
                                label: this.state.instanceCategory == 'ΟΛΑ' ? 'Ανά Κατηγορία Αιτήματος' : 'Ανά Υποκατηγορία Αιτήματος (' + this.state.instanceCategory + ')',
                                data: result.InstanceCategories.data,
                                backgroundColor: colors
                            },
                        ],
                    },
                    energyKind: {
                        labels: result.EnergyKind.labels,
                        datasets: [
                            {
                                label: 'Ανά Είδος Ενέργειας',
                                data: result.EnergyKind.data,
                                backgroundColor: colors
                            },
                        ],
                    },
                    // instanceStatus: {
                    //     labels: result.InstanceStatus.labels,
                    //     datasets: [
                    //         {
                    //             label: 'Ανά Κατάσταση Παραπόνου',
                    //             data: result.InstanceStatus.data,
                    //             backgroundColor: colors
                    //         }
                    //     ]
                    // },
                    instanceStep: {
                        labels: result.InstanceStep.labels,
                        datasets: [
                            {
                                label: 'Ανά Βήμα Αίτησης',
                                data: result.InstanceStep.data,
                                backgroundColor: colors
                            }
                        ]
                    },
                    senderAccept: {
                        labels: result.ConsumerAnswers.labels,
                        datasets: [
                            {
                                label: 'Ανά Αποδοχή Απάντησης',
                                data: result.ConsumerAnswers.data,
                                backgroundColor: colors
                            }
                        ]
                    },
                    ...prevState.isPendingChecked ? {
                        pendingInstances: {
                            labels: result.PendingDuration.labels,
                            datasets: [
                                {
                                    label: 'Ανά Μέσο Χρόνο Εκκρεμότητας',
                                    data: result.PendingDuration.data,
                                    backgroundColor: colors
                                }
                            ]
                        }
                    } : {},
                    responseTime: {
                        labels: result.ResponseTime.labels,
                        datasets: [
                            {
                                label: 'Ανά Χρονικό Διάστημα πρώτης Απάντησης',
                                data: result.ResponseTime.data,
                                backgroundColor: colors
                            }
                        ]
                    },
                    // declinedBy: {
                    //     labels: result.RaeInterventions.labels,
                    //     datasets: [
                    //         {
                    //             label: 'Επεμβάσεις ΡΑΕ',
                    //             data: result.RaeInterventions.data,
                    //             backgroundColor: colors
                    //         }
                    //     ]
                    // },
                    InTime: {
                        labels: result.InTime.labels,
                        datasets: [
                            {
                                label: 'Χρονικές Αποκρίσεις Ενεργών Αναφορών',
                                data: result.InTime.data,
                                backgroundColor: colors
                            }
                        ]
                    }
                },
                showCharts: 1,
                loadingCharts: false
            }))
            // zipping Categories values and labels
            let labels = this.state.chartData.instanceCategory.labels
            let values = this.state.chartData.instanceCategory.datasets[0].data
            let zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            this.setState({ zippedInstanceCategory: zipped })

            // zipping Statuses values and labels
            // labels = this.state.chartData.instanceStatus.labels
            // values = this.state.chartData.instanceStatus.datasets[0].data
            // zipped = labels.map(function (value, i) {
            //     return [value, values[i]];
            // });
            // this.setState({ zippedInstanceStatus: zipped })

            // zipping Steps values and labels
            labels = this.state.chartData.instanceStep.labels
            values = this.state.chartData.instanceStep.datasets[0].data
            zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            this.setState({ zippedInstanceStep: zipped })

            // zipping Receiver Names values and labels
            labels = this.state.chartData.receiverName.labels
            values = this.state.chartData.receiverName.datasets[0].data
            zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            // var filteredZipped = zipped.filter(e => e[0] !== 'Δοκιμαστικός Παραλήπτης')
            this.setState({ zippedReceiverName: zipped })

            // zipping Receiver Kinds values and labels
            labels = this.state.chartData.receiverKind.labels
            values = this.state.chartData.receiverKind.datasets[0].data
            zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            this.setState({ zippedReceiverKind: zipped })

            // zipping Energy Kinds values and labels
            labels = this.state.chartData.energyKind.labels
            values = this.state.chartData.energyKind.datasets[0].data
            zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            this.setState({ zippedEnergyKind: zipped })

            // zipping Sender Accept values and labels
            labels = this.state.chartData.senderAccept.labels
            values = this.state.chartData.senderAccept.datasets[0].data
            zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            this.setState({ zippedSenderAccept: zipped })

            // zipping Pending Instances values and labels
            if (this.state.isPendingChecked) {
                const labels = this.state.chartData.pendingInstances.labels;
                const values = this.state.chartData.pendingInstances.datasets[0].data;

                const zipped = labels.map(function (value, i) {
                    return [value, values[i]];
                });

                this.setState({ zippedPendingInstances: zipped });
            }

            // zipping Response Time values and labels
            labels = this.state.chartData.responseTime.labels
            values = this.state.chartData.responseTime.datasets[0].data
            zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            this.setState({ zippedResponseTime: zipped })

            // zipping RaeInterventions values and labels
            // labels = this.state.chartData.declinedBy.labels
            // values = this.state.chartData.declinedBy.datasets[0].data
            // zipped = labels.map(function (value, i) {
            //     return [value, values[i]];
            // });
            // this.setState({ zippedDeclinedBy: zipped })

            labels = this.state.chartData.InTime.labels
            values = this.state.chartData.InTime.datasets[0].data
            zipped = labels.map(function (value, i) {
                return [value, values[i]];
            });
            this.setState({ zippedInTime: zipped })
        }
    }

    componentDidMount = async () => {
        if (localStorage.getItem('token')) {

            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/name", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result[0] != -1) {
                            this.setState({
                                user: result,
                                isAdmin: false
                            });
                        }
                    }
                )
        }
        const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/lists/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                let m = res.data.m
                let filteredMaintainers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                m.forEach(function (element, index) {
                    filteredMaintainers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                // var filteredMAINTAINERS = filteredMaintainers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
                let uniqueMaintainers = [...new Set(filteredMaintainers.map(({ value }) => value))].map(e => filteredMaintainers.find(({ value }) => value == e));

                let s = res.data.s
                let filteredSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                s.forEach(function (element, index) {
                    filteredSuppliers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                // var filteredSUPPLIERS = filteredSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
                let uniqueSuppliers = [...new Set(filteredSuppliers.map(({ value }) => value))].map(e => filteredSuppliers.find(({ value }) => value == e));

                let all = res.data.all
                let filteredAllSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                all.forEach(function (element, index) {
                    filteredAllSuppliers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                // var filteredALL = filteredAllSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
                let uniqueAll = [...new Set(filteredAllSuppliers.map(({ value }) => value))].map(e => filteredAllSuppliers.find(({ value }) => value == e));

                let gm = res.data.gm
                let filteredGasMaintainers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                gm.forEach(function (element, index) {
                    filteredGasMaintainers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                var filteredGM = filteredGasMaintainers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')


                let em = res.data.em
                let filteredElectricMaintainers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                em.forEach(function (element, index) {
                    filteredElectricMaintainers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                var filteredEM = filteredElectricMaintainers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')

                let gs = res.data.gs
                let filteredGasSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                gs.forEach(function (element, index) {
                    filteredGasSuppliers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                var filteredGS = filteredGasSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')

                let es = res.data.es
                let filteredElectricSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                es.forEach(function (element, index) {
                    filteredElectricSuppliers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                var filteredES = filteredElectricSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')

                let egs = res.data.egs
                let filteredGasANDElectricSuppliers = [{ value: 'ΟΛΑ', label: "'Ολα" }]
                egs.forEach(function (element, index) {
                    filteredGasANDElectricSuppliers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });
                var filteredEGS = filteredGasANDElectricSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')


                this.setState({
                    gasMaintainers: filteredGM,
                    electrMaintainers: filteredEM,
                    gasSuppliers: filteredGS,
                    electrSuppliers: filteredES,
                    gasAndElectrSuppliers: filteredEGS,
                    maintainers: uniqueMaintainers,
                    suppliers: uniqueSuppliers,
                    allSuppliers: uniqueAll,
                    instanceId: uuidv4(),
                    instanceId2: uuidv4()
                });
            })
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            )
        }
        else {
            const { energyKind, selectedOption, receiverKind, receiverName, receiverName2, instanceStatus, instanceStep, instanceSubject } = this.state;
            return (
                <>
                    {/* HEADER AND TITLE */}
                    <InstanceHeader />
                    <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
                        <Container fluid>
                            <div className="header-body">
                                <Row>
                                    <Col lg="8" xl="12">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            Παραμετρικά Στατιστικά
                                                        </span>

                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                        </Container>

                    </div>

                    {/* DROPDOWNS */}

                    <Container >
                        <br />
                        <UncontrolledAlert color="danger" toggle={this.onDismissDanger} isOpen={this.state.isOpenDanger} fade={false}>
                            <span className="alert-inner--text">
                                Δεν βρέθηκαν αποτελέσματα με τα επιλεγμένα φίλτρα!
                            </span>
                        </UncontrolledAlert>
                        {this.state.loadingCharts ?
                            <>
                                <br /><br />
                                <br /><br />
                                <br /><br />
                                <br /><br />
                                <br /><br />
                                <br /><br />
                                <br /><br />
                                <br /><br />
                                <CommonLoading />
                            </>
                            :
                            <>
                                {this.state.showCharts ?
                                    <>
                                        <br /><br />
                                        <Button
                                            color="primary"
                                            onClick={() => this.reloadFilters()}
                                            size="sm"
                                        >
                                            Αλλαγή Φίλτρων Στατιστικών
                                        </Button>
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="btn btn-success btn-sm"
                                            table="synolika"
                                            filename="Συνολικά Στατιστικά"
                                            sheet="Συνολικά Στατιστικά"
                                            buttonText="Εξαγωγή Συνολικών Στατιστικών" />
                                        <Button
                                            color="info"
                                            onClick={this.state.isAdmin ? this.downloadAllForAdmin.bind(this) : this.downloadAllForUser.bind(this)}
                                            size="sm"
                                        >
                                            Λήψη όλων των εικόνων Γραφημάτων
                                        </Button>

                                        <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col-4 col-md-4">
                                                                <h3 className="mb-0">Ανά Είδος Παραλήπτη</h3>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.toggleDisplayMode('receiverKind')}
                                                                    size="sm"
                                                                >
                                                                    Εναλλαγή ποσοστών/τιμών

                                                                </Button>
                                                                {this.state.chartType.receiverKind == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(1, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(1, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadReceiverKind.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="paraliptes"
                                                                    filename="Στατιστικά Ανά Παραλήπτη"
                                                                    sheet="Στατιστικά Ανά Παραλήπτη"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.receiverKind == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('receiverKind')} data={this.state.chartData.receiverKind} ref={this.receiverKindRef} />
                                                        : <Pie options={this.getOptionsForPieChart('receiverKind')} data={this.state.chartData.receiverKind} ref={this.receiverKindRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="paraliptes" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Ειδος Ενεργειασ</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedReceiverKind.map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sum}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>



                                                </Card>
                                            </Col>
                                        </Row>
                                        {this.state.isAdmin ? <>
                                            <Row className="mt-5">
                                                <Col className="mb-5 mb-xl-0" xl="12">
                                                    <Card className="shadow">
                                                        <CardHeader className="border-0">
                                                            <Row className="align-items-center">
                                                                <div className="col-4 col-md-4">
                                                                    <h3 className="mb-0">Ανά Χρήστη</h3>
                                                                </div>
                                                                <div className="col-8 md-8 text-right">
                                                                    <Button
                                                                        color="warning"
                                                                        onClick={() => this.toggleDisplayMode('receiverName')}
                                                                        size="sm"
                                                                    >
                                                                        Εναλλαγή ποσοστών/τιμών

                                                                    </Button>
                                                                    {this.state.chartType.receiverName == 1 ?
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => this.switchType(2, 2)}
                                                                            size="sm"
                                                                        >
                                                                            Αλλαγή σε πίτα

                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => this.switchType(2, 1)}
                                                                            size="sm"
                                                                        >
                                                                            Αλλαγή σε μπάρες
                                                                        </Button>
                                                                    }
                                                                    <Button
                                                                        color="info"
                                                                        onClick={this.downloadReceiverName.bind(this)}
                                                                        size="sm"
                                                                    >
                                                                        Λήψη Εικόνας
                                                                    </Button>
                                                                    <ReactHTMLTableToExcel
                                                                        id="test-table-xls-button"
                                                                        className="btn btn-success btn-sm"
                                                                        table="xristes"
                                                                        filename="Στατιστικά Ανά Χρήστη"
                                                                        sheet="Στατιστικά Ανά Χρήστη"
                                                                        buttonText="Εξαγωγή σε EXCEL" />
                                                                </div>
                                                            </Row>
                                                        </CardHeader>
                                                        {this.state.chartType.receiverName == 1 ?
                                                            <Bar options={this.getOptionsForBarChart('receiverName')} data={this.state.chartData.receiverName} ref={this.receiverNameRef} />
                                                            : <Pie options={this.getOptionsForPieChart('receiverName')} data={this.state.chartData.receiverName} ref={this.receiverNameRef} />
                                                        }
                                                        <br />
                                                        <Table className="align-items-center table-flush" id="xristes" responsive>
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th scope="col">Χρηστες</th>
                                                                    <th scope="col">Αριθμος Αιτηματων</th>
                                                                    <th scope="col">Ποσοστο Αιτηματων</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.zippedReceiverName.map((element) =>
                                                                        <tr>
                                                                            <td scope="row">{element[0]}</td>
                                                                            <td scope="row">{element[1]}</td>
                                                                            <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                        </tr>
                                                                    )

                                                                }
                                                                <tr>
                                                                    <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                    <th scope="row">{this.state.sum}</th>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </>
                                            :
                                            <></>
                                        }


                                        <Row className="mt-5 hidden" style={{ display: 'none' }}>
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col">
                                                                <Table className="align-items-center table-flush" id="synolika" responsive>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Ειδος Ενεργειασ</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedReceiverKind.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                    </tbody>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Χρηστες</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedReceiverName.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                    </tbody>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Κατηγορια Αιτηματος</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedInstanceCategory.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                    </tbody>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Ειδος Ενεργειας</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedEnergyKind.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                    </tbody>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Κατασταση Παραπονου</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedInstanceStep.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                    </tbody>
                                                                    {/* <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Βήμα Αίτησης</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedInstanceStep.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                    </tbody> */}
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Αποδοχη Απαντησης</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedSenderAccept.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sumAcceptance) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }

                                                                        <tr>
                                                                            <th scope="row">{'ΣΥΝΟΛΟ ΑΙΤΗΣΕΩΝ'}</th>
                                                                            <th scope="row">{this.state.sumAcceptance}</th>
                                                                        </tr>
                                                                    </tbody>
                                                                    {
                                                                        this.state.isPendingChecked ? (
                                                                            <>
                                                                                <thead className="thead-light">
                                                                                    <tr>
                                                                                        <th scope="col">Διαρκεια Αιτηματων</th>
                                                                                        <th scope="col">Αριθμος Αιτηματων</th>
                                                                                        <th scope="col">Ποσοστο Αιτηματων</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.zippedPendingInstances.map((element) =>
                                                                                            <tr key={element[0]}>
                                                                                                <td scope="row">{element[0]}</td>
                                                                                                <td scope="row">{element[1]}</td>
                                                                                                <td scope="row">{Math.round((element[1] / this.state.sumPendingInstances) * 10000) / 100 + '%'}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                    <tr>
                                                                                        <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                                        <th scope="row">{this.state.sumPendingInstances}</th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </>
                                                                        ) : null
                                                                    }

                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Χρονος Πρωτης Απαντησης</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedResponseTime.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sumResponseTime) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                        <tr>
                                                                            <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                            <th scope="row">{this.state.sumResponseTime}</th>
                                                                        </tr>
                                                                    </tbody>
                                                                    {/* <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Επεμβάσεις ΡΑΕ</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedDeclinedBy.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.sumDeclinedBy) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                        <tr>
                                                                            <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                            <th scope="row">{this.state.sumDeclinedBy}</th>
                                                                        </tr>
                                                                    </tbody> */}

                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Αναφορές με μη χρονικά προσήκουσες αποκρίσεις</th>
                                                                            <th scope="col">Αριθμος Αιτηματων</th>
                                                                            <th scope="col">Ποσοστο Αιτηματων</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.zippedInTime.map((element) =>
                                                                                <tr>
                                                                                    <td scope="row">{element[0]}</td>
                                                                                    <td scope="row">{element[1]}</td>
                                                                                    <td scope="row">{Math.round((element[1] / this.state.SumInTime) * 10000) / 100 + '%'}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                        <tr>
                                                                            <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                            <th scope="row">{this.state.SumInTime}</th>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col-4 col-md-4">
                                                                {this.state.instanceCategory == 'ΟΛΑ' ?
                                                                    <>
                                                                        <h3 className="mb-0">Ανά Κατηγορία Αιτήματος</h3>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <h3 className="mb-0">Ανά Υποκατηγορία Αιτήματος ({this.state.instanceCategory})</h3>
                                                                    </>
                                                                }

                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.toggleDisplayMode('instanceCategory')}
                                                                    size="sm"
                                                                >
                                                                    Εναλλαγή ποσοστών/τιμών

                                                                </Button>
                                                                {this.state.chartType.instanceCategory == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(3, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(3, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadInstanceCategory.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="katigories"
                                                                    filename="Στατιστικά Ανά Κατηγορία"
                                                                    sheet="Στατιστικά Ανά Κατηγορία"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.instanceCategory == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('instanceCategory')} data={this.state.chartData.instanceCategory} ref={this.instanceCategoryRef} />
                                                        : <Pie options={this.getOptionsForPieChart('instanceCategory')} data={this.state.chartData.instanceCategory} ref={this.instanceCategoryRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="katigories" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Κατηγορια Αιτηματος</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedInstanceCategory.map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sum}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col-4 col-md-4">
                                                                <h3 className="mb-0">Ανά Είδος Ενέργειας</h3>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.toggleDisplayMode('energyKind')}
                                                                    size="sm"
                                                                >
                                                                    Εναλλαγή ποσοστών/τιμών

                                                                </Button>
                                                                {this.state.chartType.energyKind == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(4, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(4, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadEnergyKind.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="energeies"
                                                                    filename="Στατιστικά Ανά Είδος Ενέργειας"
                                                                    sheet="Στατιστικά Ανά Είδος Ενέργειας"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.energyKind == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('energyKind')} data={this.state.chartData.energyKind} ref={this.energyKindRef} />
                                                        : <Pie options={this.getOptionsForPieChart('energyKind')} data={this.state.chartData.energyKind} ref={this.energyKindRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="energeies" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Ειδος Ενεργειας</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedEnergyKind.map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sum}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>

                                        {/* <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col">
                                                                <h3 className="mb-0">Ανά Κατάσταση Παραπόνου</h3>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                {this.state.chartType.instanceStatus == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(5, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(5, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadInstanceStatus.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="katastaseis"
                                                                    filename="Στατιστικά Ανά Κατάσταση Παραπόνου"
                                                                    sheet="Στατιστικά Ανά Κατάσταση Παραπόνου"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.instanceStatus == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('receiverKind')} data={this.state.chartData.instanceStatus} ref={this.instanceStatusRef} />
                                                        : <Pie options={this.getOptionsForPieChart('receiverKind')} data={this.state.chartData.instanceStatus} ref={this.instanceStatusRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="katastaseis" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Κατασταση Παραπονου</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedInstanceStatus.filter(el => el[0] != 2 && el[0] != 3 && el[0] != 1).map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sumInstanceStatus) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sumInstanceStatus}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row> */}

                                        <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col-4 col-md-4">
                                                                <h3 className="mb-0">Ανά Βήμα Αίτησης</h3>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.toggleDisplayMode('instanceStep')}
                                                                    size="sm"
                                                                >
                                                                    Εναλλαγή ποσοστών/τιμών

                                                                </Button>
                                                                {this.state.chartType.instanceStep == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(5, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(5, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadInstanceStep.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="vimata"
                                                                    filename="Στατιστικά Ανά Βήμα Αίτησης"
                                                                    sheet="Στατιστικά Ανά Βήμα Αίτησης"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.instanceStep == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('instanceStep')} data={this.state.chartData.instanceStep} ref={this.instanceStepRef} />
                                                        : <Pie options={this.getOptionsForPieChart('instanceStep')} data={this.state.chartData.instanceStep} ref={this.instanceStepRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="vimata" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Βήμα Αίτησης</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedInstanceStep.filter(el => el[0] != 2 && el[0] != 3 && el[0] != 1).map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sum) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sum}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col-4 col-md-4">
                                                                <h3 className="mb-0">Ανά Αποδοχή Απάντησης</h3>
                                                                <h5>Αφορά τα ολοκληρωμένα Αιτήματα</h5>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.toggleDisplayMode('senderAccept')}
                                                                    size="sm"
                                                                >
                                                                    Εναλλαγή ποσοστών/τιμών

                                                                </Button>
                                                                {this.state.chartType.senderAccept == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(6, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(6, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadSenderAccept.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="apodoxes"
                                                                    filename="Στατιστικά Ανά Αποδοχή Απάντησης"
                                                                    sheet="Στατιστικά Ανά Αποδοχή Απάντησης"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.senderAccept == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('senderAccept')} data={this.state.chartData.senderAccept} ref={this.senderAcceptRef} />
                                                        : <Pie options={this.getOptionsForPieChart('senderAccept')} data={this.state.chartData.senderAccept} ref={this.senderAcceptRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="apodoxes" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Αποδοχη Απαντησης</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedSenderAccept.map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sumAcceptance) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sumAcceptance}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {this.state.isPendingChecked ?
                                            <>
                                                <Row className="mt-5">
                                                    <Col className="mb-5 mb-xl-0" xl="12">
                                                        <Card className="shadow">
                                                            <CardHeader className="border-0">
                                                                <Row className="align-items-center">
                                                                    <div className="col-4 col-md-4">
                                                                        <h3 className="mb-0">Ανά Μέσο Χρόνο Εκκρεμότητας</h3>
                                                                        <h5>Αφορά τα ολοκληρωμένα Αιτήματα</h5>
                                                                    </div>
                                                                    <div className="col-8 md-8 text-right">
                                                                        <Button
                                                                            color="warning"
                                                                            onClick={() => this.toggleDisplayMode('pendingInstances')}
                                                                            size="sm"
                                                                        >
                                                                            Εναλλαγή ποσοστών/τιμών

                                                                        </Button>
                                                                        {this.state.chartType.pendingInstances == 1 ?
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => this.switchType(7, 2)}
                                                                                size="sm"
                                                                            >
                                                                                Αλλαγή σε πίτα

                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => this.switchType(7, 1)}
                                                                                size="sm"
                                                                            >
                                                                                Αλλαγή σε μπάρες
                                                                            </Button>
                                                                        }
                                                                        <Button
                                                                            color="info"
                                                                            onClick={this.downloadPendingInstances.bind(this)}
                                                                            size="sm"
                                                                        >
                                                                            Λήψη Εικόνας
                                                                        </Button>
                                                                        <ReactHTMLTableToExcel
                                                                            id="test-table-xls-button"
                                                                            className="btn btn-success btn-sm"
                                                                            table="diarkeies"
                                                                            filename="Στατιστικά Ανά Μέσο Χρόνο Εκκρεμότητας"
                                                                            sheet="Στατιστικά Ανά Μέσο Χρόνο Εκκρεμότητας"
                                                                            buttonText="Εξαγωγή σε EXCEL" />
                                                                    </div>
                                                                </Row>
                                                            </CardHeader>
                                                            {this.state.chartType.pendingInstances == 1 ?
                                                                <Bar options={this.getOptionsForBarChart('pendingInstances')} data={this.state.chartData.pendingInstances} ref={this.pendingInstancesRef} />
                                                                : <Pie options={this.getOptionsForPieChart('pendingInstances')} data={this.state.chartData.pendingInstances} ref={this.pendingInstancesRef} />
                                                            }
                                                            <br />
                                                            <Table className="align-items-center table-flush" id="diarkeies" responsive>
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th scope="col">Διαρκεια Αιτηματων</th>
                                                                        <th scope="col">Αριθμος Αιτηματων</th>
                                                                        <th scope="col">Ποσοστο Αιτηματων</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.zippedPendingInstances.map((element) =>
                                                                            <tr>
                                                                                <td scope="row">{element[0]}</td>
                                                                                <td scope="row">{element[1]}</td>
                                                                                <td scope="row">{Math.round((element[1] / this.state.sumPendingInstances) * 10000) / 100 + '%'}</td>
                                                                            </tr>
                                                                        )

                                                                    }
                                                                    <tr>
                                                                        <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                        <th scope="row">{this.state.sumPendingInstances}</th>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </> : <></>}

                                        <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col-4 col-md-4">
                                                                <h3 className="mb-0">Ανά Χρονικό Διάστημα πρώτης Απάντησης</h3>
                                                                <h5>Αφορά τα Απαντημένα Αιτήματα</h5>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.toggleDisplayMode('responseTime')}
                                                                    size="sm"
                                                                >
                                                                    Εναλλαγή ποσοστών/τιμών

                                                                </Button>
                                                                {this.state.chartType.responseTime == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(8, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(8, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadResponseTime.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="apantiseis"
                                                                    filename="Στατιστικά Ανά Χρονικό Διάστημα πρώτης Απάντησης"
                                                                    sheet="Στατιστικά Ανά Χρονικό Διάστημα πρώτης Απάντησης"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.responseTime == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('responseTime')} data={this.state.chartData.responseTime} ref={this.responseTimeRef} />
                                                        : <Pie options={this.getOptionsForPieChart('responseTime')} data={this.state.chartData.responseTime} ref={this.responseTimeRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="apantiseis" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Χρονος Πρωτης Απαντησης</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedResponseTime.map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sumResponseTime) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sumResponseTime}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {/* <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col">
                                                                <h3 className="mb-0">Επεμβάσεις ΡΑΕ</h3>
                                                                <h5>Αφορά τα Απορριφθέντα Αιτήματα</h5>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                {this.state.chartType.declinedBy == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(9, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(9, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadDeclinedBy.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="epemvaseisrae"
                                                                    filename="Στατιστικά Επεμβάσεων ΡΑΕ"
                                                                    sheet="Στατιστικά Επεμβάσεων ΡΑΕ"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.declinedBy == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('receiverKind')} data={this.state.chartData.declinedBy} ref={this.declinedByRef} />
                                                        : <Pie options={this.getOptionsForPieChart('receiverKind')} data={this.state.chartData.declinedBy} ref={this.declinedByRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="epemvaseisrae" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Επεμβάσεις ΡΑΕ</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedDeclinedBy.map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.sumDeclinedBy) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.sumDeclinedBy}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row> */}
                                        <Row className="mt-5">
                                            <Col className="mb-5 mb-xl-0" xl="12">
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <Row className="align-items-center">
                                                            <div className="col-4 col-md-4">
                                                                <h3 className="mb-0">Αναφορές με μη χρονικά προσήκουσες αποκρίσεις</h3>
                                                            </div>
                                                            <div className="col-8 md-8 text-right">
                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.toggleDisplayMode('InTime')}
                                                                    size="sm"
                                                                >
                                                                    Εναλλαγή ποσοστών/τιμών

                                                                </Button>
                                                                {this.state.chartType.InTime == 1 ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(9, 2)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε πίτα

                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.switchType(9, 1)}
                                                                        size="sm"
                                                                    >
                                                                        Αλλαγή σε μπάρες
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    color="info"
                                                                    onClick={this.downloadInTime.bind(this)}
                                                                    size="sm"
                                                                >
                                                                    Λήψη Εικόνας
                                                                </Button>
                                                                <ReactHTMLTableToExcel
                                                                    id="test-table-xls-button"
                                                                    className="btn btn-success btn-sm"
                                                                    table="improper"
                                                                    filename="Στατιστικά Αναφορών με μη χρονικά προσήκουσες αποκρίσεις"
                                                                    sheet="Στατιστικά Αναφορών με μη χρονικά προσήκουσες αποκρίσεις"
                                                                    buttonText="Εξαγωγή σε EXCEL" />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    {this.state.chartType.InTime == 1 ?
                                                        <Bar options={this.getOptionsForBarChart('InTime')} data={this.state.chartData.InTime} ref={this.InTimeRef} />
                                                        : <Pie options={this.getOptionsForPieChart('InTime')} data={this.state.chartData.InTime} ref={this.InTimeRef} />
                                                    }
                                                    <br />
                                                    <Table className="align-items-center table-flush" id="improper" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Αναφορές με μη χρονικά προσήκουσες αποκρίσεις</th>
                                                                <th scope="col">Αριθμος Αιτηματων</th>
                                                                <th scope="col">Ποσοστο Αιτηματων</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.zippedInTime.map((element) =>
                                                                    <tr>
                                                                        <td scope="row">{element[0]}</td>
                                                                        <td scope="row">{element[1]}</td>
                                                                        <td scope="row">{Math.round((element[1] / this.state.SumInTime) * 10000) / 100 + '%'}</td>
                                                                    </tr>
                                                                )

                                                            }
                                                            <tr>
                                                                <th scope="row">{'ΣΥΝΟΛΟ'}</th>
                                                                <th scope="row">{this.state.SumInTime}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <br /><br />
                                        <h2 style={{ textAlign: 'center' }}>Φίλτρα</h2> <br />
                                        <Form>
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    <Datepicker updateStartDateProp={this.updateStartDate} updateEndDateProp={this.updateEndDate} />
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <Select
                                                        // defaultValue={{ value: 'ΟΛΑ', label: 'Όλα' }}
                                                        value={energyKind['value']}
                                                        onChange={this.changeEnergyKind}
                                                        options={[
                                                            { value: 'ΟΛΑ', label: "'Ολα" },
                                                            { value: 'Φυσικό Αέριο', label: 'Φυσικό Αέριο' },
                                                            { value: 'Ηλεκτρική Ενέργεια', label: 'Ηλεκτρική Ενέργεια' },
                                                            { value: 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο', label: 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' },
                                                        ]}
                                                        placeholder={"Είδος Ενέργειας"}
                                                    />
                                                </Col>
                                                {this.state.energyKind == 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' ?
                                                    <Col xs={6} md={6}>
                                                        <Select
                                                            value={'Προμηθευτής Ενέργειας'}
                                                            isDisabled={true}
                                                            placeholder={'Προμηθευτής Ενέργειας'}
                                                        />
                                                    </Col>
                                                    :
                                                    <>
                                                        <Col xs={6} md={6}>
                                                            <Select
                                                                // defaultValue={{ value: 'ΟΛΑ', label: 'Όλα' }}
                                                                value={receiverKind['value']}
                                                                isDisabled={!this.state.isAdmin}
                                                                onChange={this.changeReceiverKind}
                                                                options={[
                                                                    { value: 'ΟΛΑ', label: "'Ολα" },
                                                                    { value: 'Διαχειριστής Δικτύου', label: 'Διαχειριστής Δικτύου' },
                                                                    { value: 'Προμηθευτής Ενέργειας', label: 'Προμηθευτής Ενέργειας' },
                                                                    { value: 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας', label: 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας' },
                                                                ]}
                                                                placeholder={"Επιλογή Είδος Παραλήπτη"}
                                                            />
                                                        </Col>
                                                    </>
                                                }

                                                {this.state.energyKind == 'Φυσικό Αέριο' && this.state.receiverKind == 'Διαχειριστής Δικτύου' && this.state.isAdmin ?
                                                    <>
                                                        <Col xs={6} md={6}>
                                                            <Select
                                                                defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                value={receiverName['value']}
                                                                onChange={this.changeReceiverName}
                                                                options={this.state.gasMaintainers}
                                                                placeholder={"Επιλογή διαχειριστή (Φυσικό Αέριο)"}
                                                            />
                                                        </Col>
                                                    </>
                                                    : <> {this.state.energyKind == 'Φυσικό Αέριο' && this.state.receiverKind == 'Προμηθευτής Ενέργειας' && this.state.isAdmin ?
                                                        <>
                                                            <Col xs={6} md={6}>
                                                                <Select
                                                                    defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                    value={receiverName['value']}
                                                                    onChange={this.changeReceiverName}
                                                                    options={this.state.gasSuppliers}
                                                                    placeholder={"Επιλογή προμηθευτή (Φυσικό Αέριο)"}
                                                                />
                                                            </Col>
                                                        </> : <></>
                                                    }
                                                        {/* {this.state.energyKind == 'Φυσικό Αέριο' && this.state.receiverKind == 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας' ?
                                                            <>
                                                                <Col xs={6} md={6}>
                                                                    <Select
                                                                        defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                        value={receiverName['value']}
                                                                        onChange={this.changeReceiverName}
                                                                        options={this.state.gasMaintainers}
                                                                        placeholder={"Επιλογή διαχειριστή (Φυσικό Αέριο)"}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={6}>
                                                                    <Select
                                                                        defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                        value={receiverName2['value']}
                                                                        onChange={this.changeReceiverName2}
                                                                        options={this.state.gasSuppliers}
                                                                        placeholder={"Επιλογή προμηθευτή (Φυσικό Αέριο)"}
                                                                    />
                                                                </Col>
                                                            </> : <></>} */}
                                                    </>
                                                }

                                                {this.state.energyKind == 'Ηλεκτρική Ενέργεια' && this.state.receiverKind == 'Διαχειριστής Δικτύου' && this.state.isAdmin ?
                                                    <>
                                                        <Col xs={6} md={6}>
                                                            <Select
                                                                defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                value={receiverName['value']}
                                                                onChange={this.changeReceiverName}
                                                                options={this.state.electrMaintainers}
                                                                placeholder={"Επιλογή διαχειριστή (Ηλεκτρική Ενέργεια)"}
                                                            />
                                                        </Col>
                                                    </>
                                                    : <> {this.state.energyKind == 'Ηλεκτρική Ενέργεια' && this.state.receiverKind == 'Προμηθευτής Ενέργειας' && this.state.isAdmin ?
                                                        <>
                                                            <Col xs={6} md={6}>
                                                                <Select
                                                                    defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                    value={receiverName['value']}
                                                                    onChange={this.changeReceiverName}
                                                                    options={this.state.electrSuppliers}
                                                                    placeholder={"Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια)"}
                                                                />
                                                            </Col>
                                                        </> : <></>
                                                    }
                                                        {/* {this.state.energyKind == 'Ηλεκτρική Ενέργεια' && this.state.receiverKind == 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας' ?
                                                            <>
                                                                <Col xs={6} md={6}>
                                                                    <Select
                                                                        defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                        value={receiverName['value']}
                                                                        onChange={this.changeReceiverName}
                                                                        options={this.state.electrMaintainers}
                                                                        placeholder={"Επιλογή διαχειριστή (Ηλεκτρική Ενέργεια)"}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={6}>
                                                                    <Select
                                                                        defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                        value={receiverName2['value']}
                                                                        onChange={this.changeReceiverName2}
                                                                        options={this.state.electrSuppliers}
                                                                        placeholder={"Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια)"}
                                                                    />
                                                                </Col>
                                                            </> : <></>} */}
                                                    </>
                                                }
                                                {this.state.energyKind == 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' && this.state.isAdmin ?
                                                    <>
                                                        <Col xs={6} md={6}>
                                                            <Select
                                                                defaultValue={{ value: 'ΟΛΑ', label: "'Ολα" }}
                                                                value={receiverName['value']}
                                                                onChange={this.changeReceiverName}
                                                                options={this.state.gasSuppliers}
                                                                placeholder={"Επιλογή προμηθευτή (Ηλεκτρική Ενέργεια & Φυσικό Αέριο)"}
                                                            />
                                                        </Col>
                                                    </>
                                                    : <></>}
                                                <br />
                                                <br />
                                                {(this.state.receiverKind == 'ΟΛΑ' || this.state.receiverKind == 'Διαχειριστής Δικτύου και Προμηθευτής Ενέργειας') && this.state.energyKind != 'Ηλεκτρική Ενέργεια & Φυσικό Αέριο' && this.state.isAdmin ?
                                                    <Col xs={6} md={6}>
                                                        <Select
                                                            value={receiverName['value']}
                                                            onChange={this.changeReceiverName}
                                                            options={this.state.allSuppliers}
                                                            placeholder={"Επιλογή παραλήπτη"}
                                                        />
                                                    </Col>
                                                    :
                                                    <></>}
                                                {this.state.receiverKind == 'Διαχειριστής Δικτύου' && this.state.energyKind == 'ΟΛΑ' && this.state.isAdmin ?
                                                    <Col xs={6} md={6}>
                                                        <Select
                                                            value={receiverName['value']}
                                                            onChange={this.changeReceiverName}
                                                            options={this.state.maintainers}
                                                            placeholder={"Επιλογή Διαχειριστή"}
                                                        />
                                                    </Col>
                                                    :
                                                    <></>}
                                                {this.state.receiverKind == 'Προμηθευτής Ενέργειας' && this.state.energyKind == 'ΟΛΑ' && this.state.isAdmin ?
                                                    <Col xs={6} md={6}>
                                                        <Select
                                                            value={receiverName['value']}
                                                            onChange={this.changeReceiverName}
                                                            options={this.state.suppliers}
                                                            placeholder={"Επιλογή Προμηθευτή"}
                                                        />
                                                    </Col>
                                                    :
                                                    <></>}
                                            </Row>

                                            <br />

                                            <Accordion>
                                                <Container>
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <CardHeader className="bg-white border-0">
                                                                    <Accordion.Toggle as={CardHeader} eventKey={1} >
                                                                        <Row className="align-items-center">
                                                                            <Col xs="8">
                                                                                <h3 className="mb-0">Δευτερεύοντα Φίλτρα</h3>
                                                                            </Col>
                                                                            <Col className="text-right" xs="4">

                                                                            </Col>
                                                                        </Row>
                                                                    </Accordion.Toggle>
                                                                </CardHeader>
                                                                <Accordion.Collapse eventKey={1}>
                                                                    <CardBody>
                                                                        <Form>
                                                                            <div className="pl-lg-4">
                                                                                <Row>
                                                                                    {/* <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="instance-status"
                                                                                            >
                                                                                                Κατάσταση Παραπόνου
                                                                                            </label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="instance-status"
                                                                                                type="select"
                                                                                                placeholder="-- Κατάσταση Παραπόνου --"
                                                                                                onChange={event => this.changeInstanceStatus(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='Αποδοχή Απάντησης' label="Αποδοχή Απάντησης">Αποδοχή Απάντησης</option>
                                                                                                <option value='Ολοκληρωμένα με Απόρριψη Απάντησης' label="Ολοκληρωμένα με Απόρριψη Απάντησης">Ολοκληρωμένα με Απόρριψη Απάντησης</option>
                                                                                                <option value='Σε Επεξεργασία' label="Σε Επεξεργασία">Σε Επεξεργασία</option>
                                                                                                <option value='Αναπάντητα' label="Αναπάντητα">Αναπάντητα</option>
                                                                                                <option value='Απορριφθέντα από Προμηθευτή/Διαχειριστή' label="Απορριφθέντα από Προμηθευτή/Διαχειριστή">Απορριφθέντα από Προμηθευτή/Διαχειριστή</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col> */}
                                                                                    <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="instance-step"
                                                                                            >
                                                                                                Βήμα Αίτησης
                                                                                            </label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="instance-step"
                                                                                                type="select"
                                                                                                placeholder="-- Βήμα Αίτησης --"
                                                                                                onChange={event => this.changeInstanceStep(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='Αναμονή Πρώτης Απάντησης' label="Αναμονή Πρώτης Απάντησης">Αναμονή Πρώτης Απάντησης</option>
                                                                                                <option value='Αίτημα σε Εξέταση' label="Αίτημα σε Εξέταση">Αίτημα σε Εξέταση</option>
                                                                                                <option value='Απευθείας Απόρριψη' label="Απευθείας Απόρριψη">Απευθείας Απόρριψη</option>
                                                                                                <option value='Ολοκληρωμένα' label="Ολοκληρωμένα">Ολοκληρωμένα</option>
                                                                                                <option value='Μη ανταπόκριση Καταναλωτή' label="Μη ανταπόκριση Καταναλωτή">Μη ανταπόκριση Καταναλωτή</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    {/* <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <label
                                                                                            className="form-control-label"
                                                                                            htmlFor="instance-category"
                                                                                        >
                                                                                            Κατηγορία Αιτήματος
                                                                                        </label>
                                                                                        &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-instance-category'} aria-hidden="true"></i>
                                                                                        <Input
                                                                                            className="form-control-alternative"
                                                                                            id="instance-category"
                                                                                            type="select"
                                                                                            placeholder="-- Κατηγορία Αιτήματος --"
                                                                                            onChange={this.changeInstanceCategory}
                                                                                        >
                                                                                            <option value='ΟΛΑ'>ΟΛΑ</option>
                                                                                            <option value='ΣΥΜΒΑΣΗ'>ΣΥΜΒΑΣΗ</option>
                                                                                            <option value='ΠΛΗΡΩΜΕΣ'>ΠΛΗΡΩΜΕΣ</option>
                                                                                            <option value='ΕΞΥΠΗΡΕΤΗΣΗ'>ΕΞΥΠΗΡΕΤΗΣΗ</option>
                                                                                            <option value='ΛΟΓΑΡΙΑΣΜΟΣ'>ΛΟΓΑΡΙΑΣΜΟΣ</option>
                                                                                            <option value='ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ'>ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ</option>
                                                                                            <option value='ΑΛΛΑΓΗ ΠΡΟΜΗΘΕΥΤΗ'>ΑΛΛΑΓΗ ΠΡΟΜΗΘΕΥΤΗ</option>
                                                                                            <option value='ΤΕΧΝΙΚΑ ΠΡΟΒΛΗΜΑΤΑ'>ΤΕΧΝΙΚΑ ΠΡΟΒΛΗΜΑΤΑ</option>
                                                                                            <option value='ΑΛΛΟ'>ΑΛΛΟ</option>
                                                                                        </Input>
                                                                                        <ReactTooltip id={'info-instance-category'} type='info'>
                                                                                            <span>Κατηγορία Αιτήματος</span>
                                                                                        </ReactTooltip>
                                                                                    </FormGroup>
                                                                                </Col> */}
                                                                                    {/* <Col lg="6">
                                                                                    <FormGroup>
                                                                                        <label
                                                                                            className="form-control-label"
                                                                                            htmlFor="instance-subject"
                                                                                        >
                                                                                            Υποκατηγορία Αιτήματος
                                                                                        </label>
                                                                                        <Input
                                                                                            className="form-control-alternative"
                                                                                            defaultValue={instanceSubject}
                                                                                            id="instance-subject"
                                                                                            placeholder="Υποκατηγορία Αιτήματος"
                                                                                            type="text"
                                                                                            onChange={event => this.changeInstanceSubject(event)}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col> */}
                                                                                    <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="sender-accept"
                                                                                            >
                                                                                                Αποδοχή Απάντησης απο Καταναλωτή
                                                                                            </label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="sender-accept"
                                                                                                type="select"
                                                                                                placeholder="-- Αποδοχή Απάντησης απο Καταναλωτή --"
                                                                                                onChange={event => this.changeSenderAccept(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='yes' label="Ναι">Ναι</option>
                                                                                                <option value='no' label="'Οχι">'Οχι</option>
                                                                                                <option value='worker' label="Μη ανταπόκριση Καταναλωτή">Μη ανταπόκριση Καταναλωτή</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="pending-instances"
                                                                                            >
                                                                                                Μέσος Χρόνος Εκκρεμότητας Αιτημάτων
                                                                                            </label>

                                                                                            <Label check style={{ paddingLeft: '30px' }}>
                                                                                                <Input
                                                                                                    type="checkbox"
                                                                                                    checked={this.state.isPendingChecked}
                                                                                                    onChange={this.togglePendingCheck}
                                                                                                // style={{ transform: 'scale(1.5)' }}
                                                                                                />{' '}
                                                                                                Προβολή Στατιστικού
                                                                                            </Label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="pending-instances"
                                                                                                type="select"
                                                                                                placeholder="-- Μέσος Χρόνος Εκκρεμότητας Αιτημάτων --"
                                                                                                onChange={event => this.changePendingInstances(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='0-5 εργάσιμες ημέρες' label="0-5 εργάσιμες ημέρες">0-5 εργάσιμες ημέρες</option>
                                                                                                <option value='6-9 εργάσιμες ημέρες' label="6-9 εργάσιμες ημέρες">6-9 εργάσιμες ημέρες</option>
                                                                                                <option value='10+ εργάσιμες ημέρες' label="10+ εργάσιμες ημέρες">10+ εργάσιμες ημέρες</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="response-time"
                                                                                            >
                                                                                                Χρόνος Πρώτης Απάντησης από την Εταιρεία
                                                                                            </label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="response-time"
                                                                                                type="select"
                                                                                                placeholder="-- Χρόνος Πρώτης Απάντησης από την Εταιρεία --"
                                                                                                onChange={event => this.changeResponseTime(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='0-3 ημέρες' label="0-3 ημέρες">0-3 ημέρες</option>
                                                                                                <option value='4-6 ημέρες' label="4-6 ημέρες">4-6 ημέρες</option>
                                                                                                <option value='7-9 ημέρες' label="7-9 ημέρες">7-9 ημέρες</option>
                                                                                                <option value='10+ ημέρες' label="10+ ημέρες">10+ ημέρες</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    {/* <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="instance-category"
                                                                                            >
                                                                                                Επιλέξτε Κατηγορία Αιτήματος
                                                                                            </label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="instance-category"
                                                                                                type="select"
                                                                                                placeholder="-- Επιλέξτε Κατηγορία --"
                                                                                                onChange={event => this.changeInstanceCategory(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='Την Σύμβαση με την εταιρία' label="Την Σύμβαση με την εταιρία">Την Σύμβαση με την εταιρία</option>
                                                                                                <option value='Την καταγγελία της Σύμβασης' label="Την καταγγελία της Σύμβασης">Την καταγγελία της Σύμβασης</option>
                                                                                                <option value='Τις πληρωμές των λογαριασμών ' label="Τις πληρωμές των λογαριασμών ">Τις πληρωμές των λογαριασμών </option>
                                                                                                <option value='Την εξυπηρέτηση μου από την εταιρία' label="Την εξυπηρέτηση μου από την εταιρία">Την εξυπηρέτηση μου από την εταιρία</option>
                                                                                                <option value='Τον λογαριασμό μου' label="Τον λογαριασμό μου">'Τον λογαριασμό μου</option>
                                                                                                <option value='Την καταχώρηση των στοιχείων μου' label="Την καταχώρηση των στοιχείων μου">Την καταχώρηση των στοιχείων μου</option>
                                                                                                <option value='Την αλλαγή Προμηθευτή' label="Την αλλαγή Προμηθευτή">Την αλλαγή Προμηθευτή</option>
                                                                                                <option value='Νέα σύνδεση' label="Νέα σύνδεση">Νέα σύνδεση</option>
                                                                                                <option value='Εργασίες σε μετρητές' label="Εργασίες σε μετρητές">Εργασίες σε μετρητές</option>
                                                                                                <option value='Μετρήσεις κατανάλωσης' label="Μετρήσεις κατανάλωσης">Μετρήσεις κατανάλωσης</option>
                                                                                                <option value='Τεχνικά προβλήματα' label="Τεχνικά προβλήματα">Τεχνικά προβλήματα</option>
                                                                                                <option value='Εξυπηρέτηση Πελατών' label="Εξυπηρέτηση Πελατών">Εξυπηρέτηση Πελατών</option>
                                                                                                <option value='Μετρήσεις' label="Μετρήσεις">Μετρήσεις</option>
                                                                                                <option value='Άλλο' label="Άλλο">Άλλο</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col> */}
                                                                                    <Col lg="6">
                                                                                        <React.Fragment>
                                                                                            <Row>
                                                                                                <Col style={{ paddingTop: '13px' }}>
                                                                                                    <h4>Επιλέξτε Κατηγορία Αιτήματος</h4>
                                                                                                    <Cascader
                                                                                                        id={'cascader'}
                                                                                                        className={"dropdown"}
                                                                                                        //options={optionsDropdown}
                                                                                                        options={this.state.dynamicCategoryOptions} //StateActionsLiterals.GenerateOptions(ViewType.rae)}
                                                                                                        onChange={event => this.changeInstanceCategoryANDSubject(event)}
                                                                                                        placeholder={"Επιλέξτε Κατηγορία/Υποκατηγορία Αιτήματος"}
                                                                                                        style={{ width: '100%', alignItems: 'center' }}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </React.Fragment>
                                                                                    </Col>
                                                                                    {/* <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="rae-interventions"
                                                                                            >
                                                                                                Επιλέξτε Επέμβαση ΡΑΕ/Καταναλωτή
                                                                                            </label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="rae-interventions"
                                                                                                type="select"
                                                                                                placeholder="-- Επιλέξτε Επέμβαση ΡΑΕ/Καταναλωτή --"
                                                                                                onChange={event => this.changeDeclinedBy(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='Απόρριψη αιτήματος καταναλωτή' label="Απόρριψη αιτήματος καταναλωτή">Απόρριψη αιτήματος καταναλωτή</option>
                                                                                                <option value='Απόρριψη απάντησης Προμηθευτή/Διαχειριστή' label="Απόρριψη απάντησης Προμηθευτή/Διαχειριστή">Απόρριψη απάντησης Προμηθευτή/Διαχειριστή</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col> */}
                                                                                    <Col lg="6">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="in-time"
                                                                                            >
                                                                                                Επιλέξτε χρόνο απόκρισης
                                                                                            </label>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                id="improper-response-time"
                                                                                                type="select"
                                                                                                placeholder="-- Επιλέξτε είδος χρήστη --"
                                                                                                onChange={event => this.changeInTime(event)}
                                                                                            >
                                                                                                <option value='ΟΛΑ' label="'Ολα">'Ολα</option>
                                                                                                <option value='Εντός ορίων 0-5 ημέρες' label="Εντός ορίων 0-5 ημέρες">Εντός ορίων 0-5 ημέρες</option>
                                                                                                <option value='Εντός ορίων 6-10 ημέρες' label="Εντός ορίων 6-10 ημέρες">Εντός ορίων 6-10 ημέρες</option>
                                                                                                <option value='Εκτός ορίων Διαχειριστή' label="Εκτός ορίων Διαχειριστή">Εκτός ορίων Διαχειριστή</option>
                                                                                                <option value='Εντός ορίων Καταναλωτή' label="Εντός ορίων Καταναλωτή">Εντός ορίων Καταναλωτή</option>
                                                                                            </Input>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <hr className="my-4" />
                                                                        </Form>

                                                                    </CardBody>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Accordion >

                                            <br />
                                            <div style={{ textAlign: "center" }}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => { this.saveFilters() }}
                                                    size="lg"
                                                >
                                                    Προβολή Στατιστικών
                                                </Button>
                                            </div>
                                        </Form >
                                    </>
                                }
                            </>
                        }
                    </Container >

                </>
            );
        }
    }

}

export default ParameterStatistics;