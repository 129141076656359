import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import '../../assets/css/datatable.css';
import Select from 'react-select'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import Header from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';
import { MDBDataTable } from 'mdbreact';
import { CommonLoading } from 'react-loadingg';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

var paroxoi_options = [{ value: -1, label: 'Όλοι οι Πάροχοι/Διαχειριστές' }];
var categories_options = [{ value: -1, label: 'Όλες οι Κατηγορίες' }];

function iterate_suppliers(item) {
    paroxoi_options.push({
        value: item,
        label: item
    });

}

function iterate_categories(item) {
    categories_options.push({
        value: item,
        label: item
    });

}

class CallCenterInstances extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            user_token: '',
            activeNav: 1,
            isLoaded: false,
            isLoading: false,
            loadingInstances: true,
            instances: [],
            user: [],
            datas: [],
            paroxoi: [],
            selected_paroxos: -1,
            selected_category: -1,
            tabledata: [],
            dummy: [],
            dummy2: [],
            usersdict: {},
            count_instances: {}
        };
    };



    async componentDidMount() {
        if (localStorage.getItem('token')) {

            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            user: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/user/usersandprovider/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {

                        const self = this;

                        result.forEach(function (element, index) {
                            self.state.usersdict[element.DisplayName] = element.Name;
                        }, result);

                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )



            await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/callcenter/all/5/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {

                        const self = this;
                        const instances = [...result];
                        instances.forEach(function (instance, index) {
                            this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
                            var dateArray = ((this[index].StartedAt).toString()).split('T');
                            var timeArray = dateArray[1].split(':');
                            this[index].StartedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
                            // this[index].Receiver = this[index].Receiver[0];
                            try {
                                this[index].CategoryData = this[index].CategoryData.split('/')[0];
                            } catch (error) {
                                console.error("Error occurred:", error);
                                // Handle the error or set a default value
                                this[index].CategoryData = ""; // or some default value
                            }
                            this[index].Buttons =
                                <React.Fragment>
                                    <Button
                                        color="primary"
                                        href={"/admin/callcenterdetails/" + this[index].Id}
                                        size="sm"
                                    >
                                        Λεπτομέρειες
                                    </Button>
                                    {this[index].Status < 3 ?
                                        <Button
                                            color="success"
                                            href={"/admin/callcenterinstance/" + this[index].Id}
                                            size="sm"
                                        >
                                            Διαχείριση
                                        </Button> : <>
                                        </>}
                                </React.Fragment>

                            this[index].Id = null;
                        }, instances);
                        this.setState({
                            loadingInstances: false,
                            dummy: {
                                columns: [
                                    {
                                        label: 'Α/Α ▼',
                                        field: 'SerialNumber',
                                        sort: 'asc',
                                        width: 100
                                    },
                                    {
                                        label: 'Κατηγορια ▼',
                                        field: 'CategoryData',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Επωνυμο ▼',
                                        field: 'SurnameData',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Ειδος Ενεργειασ ▼',
                                        field: 'ReceiverKindData',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Κατασταση ▼',
                                        field: 'NewStatus',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    // {
                                    //   label: 'απαντηση καταναλωτη ▼',
                                    //   field: 'AnswerConsumer',
                                    //   sort: 'asc',
                                    //   width: 150
                                    // },
                                    {
                                        label: 'Παραληπτης ▼',
                                        field: 'Receiver',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Ημ/νια Υποβολης ▼',
                                        field: 'StartedAt',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Ενεργειες',
                                        field: 'Buttons',
                                        sort: 'asc',
                                        width: 150
                                    },

                                ],
                                rows: instances,
                            }
                        });




                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            loadingInstances: false,
                            error
                        });
                    }
                )

                await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/callcenter/completed/5/", {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
    
                            const self = this;
                            const instances = [...result];
                            instances.forEach(function (instance, index) {
                                this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
                                var dateArray = ((this[index].StartedAt).toString()).split('T');
                                var timeArray = dateArray[1].split(':');
                                this[index].StartedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
                                // this[index].Receiver = this[index].Receiver[0];
                                try {
                                    this[index].CategoryData = this[index].CategoryData.split('/')[0];
                                } catch (error) {
                                    console.error("Error occurred:", error);
                                    // Handle the error or set a default value
                                    this[index].CategoryData = ""; // or some default value
                                }
                                this[index].Buttons =
                                    <React.Fragment>
                                        <Button
                                            color="primary"
                                            href={"/admin/callcenterdetails/" + this[index].Id}
                                            size="sm"
                                        >
                                            Λεπτομέρειες
                                        </Button>
                                        {this[index].Status < 3 ?
                                            <Button
                                                color="success"
                                                href={"/admin/callcenterinstance/" + this[index].Id}
                                                size="sm"
                                            >
                                                Διαχείριση
                                            </Button> : <>
                                            </>}
                                    </React.Fragment>
    
                            }, instances);
                            this.setState({
                                loadingInstances: false,
                                dummy2: {
                                    columns: [
                                        {
                                            label: 'Α/Α ▼',
                                            field: 'SerialNumber',
                                            sort: 'asc',
                                            width: 100
                                        },
                                        {
                                            label: 'Κατηγορια ▼',
                                            field: 'CategoryData',
                                            sort: 'asc',
                                            width: 150
                                        },
                                        {
                                            label: 'Επωνυμο ▼',
                                            field: 'SurnameData',
                                            sort: 'asc',
                                            width: 150
                                        },
                                        {
                                            label: 'Ειδος Ενεργειασ ▼',
                                            field: 'ReceiverKindData',
                                            sort: 'asc',
                                            width: 150
                                        },
                                        {
                                            label: 'Κατασταση ▼',
                                            field: 'NewStatus',
                                            sort: 'asc',
                                            width: 150
                                        },
                                        // {
                                        //   label: 'απαντηση καταναλωτη ▼',
                                        //   field: 'AnswerConsumer',
                                        //   sort: 'asc',
                                        //   width: 150
                                        // },
                                        {
                                            label: 'Παραληπτης ▼',
                                            field: 'Receiver',
                                            sort: 'asc',
                                            width: 150
                                        },
                                        {
                                            label: 'Ημ/νια Υποβολης ▼',
                                            field: 'StartedAt',
                                            sort: 'asc',
                                            width: 150
                                        },
                                        {
                                            label: 'Ενεργειες',
                                            field: 'Buttons',
                                            sort: 'asc',
                                            width: 150
                                        },
    
                                    ],
                                    rows: instances,
                                }
                            });
    
    
    
    
                        },
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                loadingInstances: false,
                                error
                            });
                        }
                    )





        };

        // this.DemoCalls();


    }

    ExportToExcel = () => {
        if (localStorage.getItem('token')) {
            this.setState({ isLoading: true });

            fetch(process.env.REACT_APP_API_LINK + '/api/export/parameterexportexcel/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'responseType': 'blob'
                }),
                body: JSON.stringify({
                    'startDate': "-",
                    'endDate': "-",
                    'selectedSupplierMaintainer': "-",
                    'selectedComplaint': "ΟΛΑ",
                    'receiverKind': "ΟΛΑ",
                    'receiverName': "ΟΛΑ",
                    'energyKind': "ΟΛΑ"
                })
            })
                .then(response => response.blob())
                .then(function (myBlob) {
                    var objectURL = URL.createObjectURL(myBlob);
                    const link = document.createElement('a');
                    link.href = objectURL;
                    link.setAttribute('download', `Αιτήματα Παραπόνων.xlsx`);

                    document.body.appendChild(link);

                    link.click();

                    link.parentNode.removeChild(link);
                })
                .finally(() => {
                    this.setState({ isLoading: false });
                });

        }
    }

    render() {


        return (
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--8" fluid>


                    {this.state.loadingInstances ?
                        <>
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <CommonLoading />
                        </>
                        :
                        <>

                            <Row className="mt-5">

                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">Ενεργά Αιτήματα Παραπόνων</h3>
                                                </Col>
                                                {/* <Col className="text-right" xs="4">

                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        onClick={this.ExportToExcel}
                                                    >
                                                        {this.state.isLoading ? 'Περιμένετε...' : 'Εξαγωγή Αιτήσεων'}
                                                    </Button>
                                                </Col> */}
                                            </Row>
                                        </CardHeader>
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <MDBDataTable
                                                        responsive
                                                        bordered
                                                        exportToCSV
                                                        hover
                                                        strip
                                                        data={this.state.dummy}
                                                        id="mdbdttable"
                                                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                        noDataComponent="Δεν υπάρχουν δεδομένα"
                                                        searchLabel="Αναζήτηση"
                                                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                                    />
                                                </div>

                                            </Row>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mt-5">

                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">Ολοκληρωμένα Αιτήματα Παραπόνων</h3>
                                                </Col>
                                                {/* <Col className="text-right" xs="4">

                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        onClick={this.ExportToExcel}
                                                    >
                                                        {this.state.isLoading ? 'Περιμένετε...' : 'Εξαγωγή Αιτήσεων'}
                                                    </Button>
                                                </Col> */}
                                            </Row>
                                        </CardHeader>
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <MDBDataTable
                                                        responsive
                                                        bordered
                                                        exportToCSV
                                                        hover
                                                        strip
                                                        data={this.state.dummy2}
                                                        id="mdbdttable"
                                                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                        noDataComponent="Δεν υπάρχουν δεδομένα"
                                                        searchLabel="Αναζήτηση"
                                                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                                    />
                                                </div>

                                            </Row>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>
                        </>}
                    <div class='push'></div>
                </Container>
            </>
        );
    }
}

export default CallCenterInstances;
