import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import '../assets/css/datatable.css';
import Select from 'react-select'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import Header from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';
import { MDBDataTable } from 'mdbreact';
import { CommonLoading } from 'react-loadingg';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

var paroxoi_options = [{ value: -1, label: 'Όλοι οι Πάροχοι/Διαχειριστές' }];
var categories_options = [{ value: -1, label: 'Όλες οι Κατηγορίες' }];

function iterate_suppliers(item) {
  paroxoi_options.push({
    value: item,
    label: item
  });

}

function iterate_categories(item) {
  categories_options.push({
    value: item,
    label: item
  });

}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user_token: '',
      activeNav: 1,
      isLoaded: false,
      isLoading: false,
      loadingInstances: true,
      instances: [],
      user: [],
      datas: [],
      paroxoi: [],
      selected_paroxos: -1,
      selected_category: -1,
      tabledata: [],
      dummy: [],
      usersdict: {},
      count_instances: {}
    };
  };

  DemoCalls = () => {
    // var filterdemo = "ΗΡΩΝ ΘΕΡΜΟΗΛΕΚΤΡΙΚΗ Α.Ε."
    // var statusdemo = 1
    fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/allbyfilter/' + this.state.selected_paroxos + '/' + this.state.selected_category + '/1/-1/-1/', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }),
      // body: JSON.stringify({
      //   'email':this.state.email
      // })
    })
      .then(res => res.json())
      .then(data => {
        const category = this.state.selected_category;
        const instances2 = data[0];
        instances2.forEach(function (part, index) {
          this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";

          var dateArray = ((this[index].SubmittedAt).toString()).split('T');
          var timeArray = dateArray[1].split(':');
          this[index].NewSubmittedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];

          if (category != '-1')
            this[index].CategoryData = category;
          else {
            try {
              this[index].CategoryData = this[index]['246ff18f-0f69-4e6c-b0e3-a29a0ce22f49'];
            }
            catch
            {

            }
            try {
              this[index].CategoryData = this[index]['4f5107d0-91dc-40a7-83ec-b53e148911fe'];
            }
            catch
            {

            }
            try {
              this[index].CategoryData = this[index]['5217ec4b-3108-44ee-99dd-93cb50475f20'];
            }
            catch
            {

            }
            try {
              this[index].CategoryData = this[index]['87b1dc2b-c427-4f9c-b775-40243a8cc702'];
            }
            catch
            {

            }
            try {
              this[index].CategoryData = this[index]['e4383376-206c-458a-b4f9-534ade1baf07'];
            }
            catch
            {

            }

          }

          this[index].Buttons =
            <React.Fragment>
              {this[index].Status == 4 ?
                <>
                  <Button
                    color="warning"
                    href={"/admin/details/" + this[index].Id}
                    size="sm"
                  >
                    Έλεγχος
                  </Button>
                </>
                :
                <>
                  <Button
                    color="primary"
                    href={"/admin/details/" + this[index].Id}
                    size="sm"
                  >
                    Λεπτομέρειες
                  </Button>
                </>}
              {this[index].Status < 3 ?
                <Button
                  color="success"
                  href={"/admin/instance/" + this[index].Id}
                  size="sm"
                >
                  Διαχείριση
                </Button> : <>
                  <Button
                    color="success"
                    href={"/admin/instance/" + this[index].Id}
                    size="sm"
                    disabled
                  >
                    Διαχείριση
                  </Button>




                </>}
            </React.Fragment>
        }, instances2);
        this.setState({
          dummy: {
            columns: [
              {
                label: 'Α/Α ▼',
                field: 'SerialNumber',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Κατηγορια ▼',
                field: 'CategoryData',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Ειδος Ενεργειασ ▼',
                field: 'ReceiverKindData',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Κατασταση ▼',
                field: 'NewStatus',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Τροποποιηθηκε απο ▼',
                field: 'EditedBy',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Ενεργειες',
                field: 'Buttons',
                sort: 'asc',
                width: 150
              },

            ],
            rows: data[0],
          }
        });
      })
  }

  async componentDidMount() {
    if (localStorage.getItem('token')) {

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/user/usersandprovider/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {

            const self = this;

            result.forEach(function (element, index) {
              self.state.usersdict[element.DisplayName] = element.Name;
            }, result);

          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/countinstances/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {

            this.setState({
              count_instances: result
            })
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/all/5/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {

            const self = this;
            const instances = [...result];
            instances.forEach(function (instance, index) {
              this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
              var dateArray = ((this[index].StartedAt).toString()).split('T');
              var timeArray = dateArray[1].split(':');
              this[index].StartedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
              // this[index].Receiver = this[index].Receiver[0];
              try {
                this[index].CategoryData = this[index].CategoryData.split('/')[0];
              } catch (error) {
                console.error("Error occurred:", error);
                // Handle the error or set a default value
                this[index].CategoryData = ""; // or some default value
              }
              this[index].Replies = this[index].Replies == 1 ? <div style={{ display: 'flex', justifyContent: 'center' }}><i class='fas fa-envelope' style={{ fontSize: '25px' }}></i></div> : this[index].Replies == 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><i class="far fa-envelope-open" style={{ fontSize: '25px' }}></i></div> : "";
              this[index].DurationWaiting = this[index].DurationWaiting != null ? this[index].DurationWaiting + ' ημέρες' : this[index].DurationWaiting;
              this[index].Buttons =
                <React.Fragment>
                  {this[index].Status == 4 && self.state.user.PersonRole == 'Admin' ?
                    <>
                      <Button
                        color="warning"
                        href={"/admin/details/" + this[index].Id}
                        size="sm"
                      >
                        Έλεγχος
                      </Button>
                    </>
                    :
                    <>
                      <Button
                        color="primary"
                        href={"/admin/details/" + this[index].Id}
                        size="sm"
                      >
                        Λεπτομέρειες
                      </Button>
                    </>}
                  {this[index].Status < 3 ?
                    <Button
                      color="success"
                      href={"/admin/instance/" + this[index].Id}
                      size="sm"
                    >
                      Διαχείριση
                    </Button> : <>
                    </>}
                </React.Fragment>

              this[index].Id = null;
            }, instances);
            this.setState({
              loadingInstances: false,
              dummy: {
                columns: [
                  {
                    label: 'Α/Α ▼',
                    field: 'SerialNumber',
                    sort: 'asc',
                    width: 100
                  },
                  {
                    label: 'Κατηγορια ▼',
                    field: 'CategoryData',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Ειδος Ενεργειασ ▼',
                    field: 'ReceiverKindData',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Κατασταση ▼',
                    field: 'NewStatus',
                    sort: 'asc',
                    width: 150
                  },
                  // {
                  //   label: 'απαντηση καταναλωτη ▼',
                  //   field: 'AnswerConsumer',
                  //   sort: 'asc',
                  //   width: 150
                  // },
                  {
                    label: 'Παραληπτης ▼',
                    field: 'Receiver',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Νεο μηνυμα ▼',
                    field: 'Replies',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Ημ/νια Υποβολης ▼',
                    field: 'StartedAt',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Αναμονη ▼',
                    field: 'DurationWaiting',
                    sort: 'asc',
                    width: 50
                  },
                  {
                    label: 'Ενεργειες',
                    field: 'Buttons',
                    sort: 'asc',
                    width: 150
                  },

                ],
                rows: instances,
              }
            });




          },
          (error) => {
            this.setState({
              isLoaded: true,
              loadingInstances: false,
              error
            });
          }
        )





    };

    // this.DemoCalls();


  }

  ExportToExcel = () => {
    if (localStorage.getItem('token')) {
      this.setState({ isLoading: true });

      fetch(process.env.REACT_APP_API_LINK + '/api/export/parameterexportexcel/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'responseType': 'blob'
        }),
        body: JSON.stringify({
          'startDate': "-",
          'endDate': "-",
          'selectedSupplierMaintainer': "-",
          'selectedComplaint': "ΟΛΑ",
          'receiverKind': "ΟΛΑ",
          'receiverName': "ΟΛΑ",
          'energyKind': "ΟΛΑ"
        })
      })
        .then(response => response.blob())
        .then(function (myBlob) {
          var objectURL = URL.createObjectURL(myBlob);
          const link = document.createElement('a');
          link.href = objectURL;
          link.setAttribute('download', `Αιτήματα Παραπόνων.xlsx`);

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });

    }
  }

  render() {


    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-1">
            <Col className="mb-5 mb-xl-0 d-flex" xl="4">
              <Card className="shadow h-100" style={{ borderColor: "gray" }}>
                <CardHeader className="border-0">
                  <Row className="align-items-center justify-content-center">
                    <div className="col">
                      <h4 className="mb-0">Ενεργά Αιτήματα Παραπόνων ({this.state.count_instances['incomplete']})</h4>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/instances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                  <hr />
                  <Row className="align-items-center">
                    <div className="col">
                      <h4 className="mb-0">Ολοκληρωμένα Αιτήματα Παραπόνων ({this.state.count_instances['completed']})</h4>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/oldinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                  {this.state.user.PersonRole == 'Admin' ?
                    <>
                      <hr />
                      <Row className="align-items-center">
                        <div className="col">
                          <h4 className="mb-0">Αιτήματα Παραπόνων προς Έλεγχο({this.state.count_instances['declined']})</h4>
                        </div>
                        <div className="col text-right">
                          <Button
                            color="primary"
                            href={"/admin/declinedinstances/"}
                            size="sm"
                          >
                            Προβολή όλων
                          </Button>
                        </div>
                      </Row>
                    </> : <>
                      <hr />
                      <Row className="align-items-center">
                        <div className="col">
                          <h4 className="mb-0">Συνολικά Αιτήματα({this.state.count_instances['completed'] + this.state.count_instances['incomplete']})</h4>
                        </div>
                      </Row>
                    </>}
                </CardHeader>
              </Card>
            </Col>

            <Col className="mb-5 mb-xl-0 d-flex" xl="4">
              <Card className="shadow h-100" style={{ borderColor: "gray" }}>
                <CardHeader className="border-0">
                  <Row className="align-items-center justify-content-center mt-2">
                    <div className="col">
                      <h4 className="mb-0">Εντός ορίων 0-5 ημέρες ({this.state.count_instances['new']})</h4>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/newinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                  <hr />
                  <Row className="align-items-center">
                    <div className="col">
                      <h4 className="mb-0">Εντός ορίων 6-10 ημέρες ({this.state.count_instances['urgent']})</h4>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/urgentinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                  <hr />
                  <Row className="align-items-center justify-content-center mt-2">
                    <div className="col">
                      <h4 className="mb-0">Εκτός ορίων Διαχειριστή ({this.state.count_instances['admin_passed']})</h4>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/adminpassedinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>

            <Col className="mb-5 mb-xl-0 d-flex" xl="4">
              <Card className="shadow h-100" style={{ borderColor: "gray" }}>
                <CardHeader className="border-0">
                  <Row className="align-items-center justify-content-center mt-5 mb-2">
                    <div className="col">
                      <h4 className="mb-0">Εκτός ορίων λόγω Καταναλωτή ({this.state.count_instances['consumer_passed']})</h4>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/consumerpassedinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                  <hr />
                  <Row className="align-items-center">
                    <div className="col">
                      <h4 className="mb-0">Εντός ορίων Καταναλωτή ({this.state.count_instances['consumer_intime']})</h4>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/consumerintimeinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          {this.state.loadingInstances ?
            <>
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <CommonLoading />
            </>
            :
            <>

              <Row className="mt-5">

                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          {/* <h3 className="mb-0">Αιτήματα Παραπόνων</h3> */}
                        </Col>
                        <Col className="text-right" xs="4">

                          <Button
                            color="success"
                            size="sm"
                            onClick={this.ExportToExcel}
                          >
                            {this.state.isLoading ? 'Περιμένετε...' : 'Εξαγωγή Αιτήσεων'}
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <MDBDataTable
                            responsive
                            bordered
                            exportToCSV
                            hover
                            strip
                            data={this.state.dummy}
                            id="mdbdttable"
                            entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                            noDataComponent="Δεν υπάρχουν δεδομένα"
                            searchLabel="Αναζήτηση"
                            paginationLabel={['Προηγούμενο', 'Επόμενο']}
                            infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                            entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                          />
                        </div>

                      </Row>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </>}
          <div class='push'></div>
        </Container>
      </>
    );
  }
}

export default Index;
