import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import background from "../../assets/img/header-background.jpg";

import ReactTooltip from 'react-tooltip';

import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Tooltip
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

class CallCenterInstance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            loading: true,
            instance: [],
            workflow: [],
            user: [],
            logged_user: [],
            steps: [],
            files: [],
            enabled: true,
            fileUpload: null,
            comment: '',
            buttonCondition: 0,
            buttonMessage: '',
            commentDisabled: true,
            isYesSelectedForSupplyContract: false,
            isSupplyContractSent: false,
            showModal: false,
            showSecondModal: false,
            declineMessage: '',
            hasBeenOpened: false,
        };
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;

        const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/callcenter/instance/" + handle + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    instance: res.data.instance,
                    workflow: res.data.workflow,
                    user: res.data.user,
                    logged_user: res.data.logged_user,
                    steps: res.data.stepsInfused,
                    fileUpload: null,
                    comment: '',
                    loading: false,
                });

            })

        const result2 = await axios.get(process.env.REACT_APP_API_LINK + "/api/files/callcenter/status/" + handle + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({

                    files: res.data
                });
            })
    };

    handleShowModal = () => {
        this.setState({ showModal: true });
    }

    handleJustCloseModal = () => {
        this.setState({ showModal: false });
    }

    handleMessageChange = (e) => {
        this.setState({ declineMessage: e.target.value });
    }

    saveInstance = stepStatus => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/update/' + this.state.instance.Id + "/", {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[stepStatus - 1])
            })
                .then(data => {
                })
        }
    }

    approveInstance = stepStatus => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/approve/' + this.state.instance.Id + "/", {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[stepStatus - 1])
            })
                .then(data => {

                    if (stepStatus == 2) {
                        window.location.replace("/admin/callcenterdetails/" + this.state.instance.Id)
                    }
                    else {
                        window.location.reload(false);
                    }

                })
        }
    }

    declineInstance = stepStatus => {

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/callcenter/decline/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'InstanceId': this.state.instance.Id,
                    'Message': this.state.declineMessage
                })
            })
                .then(data => {
                    window.location.replace("/admin/callcenterdetails/" + this.state.instance.Id + "/");
                })
        }
    }

    uploadFile(evt, actionid) {
        const formData = new FormData();
        var res = actionid.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );


        fetch(
            process.env.REACT_APP_FILEMANAGER_LINK,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instance.Id,
                        'StepId': res[0],
                        'ActionId': res[1]
                    })
                })
                    .then(data => {

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/callcenter/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                            })
                    })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    updateInputValue(evt) {
        var res = evt.target.id.split("/");
        let data = [...this.state.steps];


        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;
                        if (tempAction.action.Target == 'Σχόλιο προς καταναλωτή') {
                            if (evt.target.value != '') {
                                this.setState({ commentDisabled: false })
                            }
                            else this.setState({ commentDisabled: true })
                        }

                        this.setState({
                            steps: data
                        });
                    }
                }
            }
        }
    }

    updateComment(evt) {
        this.setState({
            comment: evt.target.value
        });
    }

    deleteFile = fileId => {
        if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε το συγκεκριμένο αρχείο;") == true) {
            if (localStorage.getItem('token')) {
                axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                    .then(res => {
                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/callcenter/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                            })
                    })
            }
        }
        else { }
    }

    render() {
        const { showModal, declineMessage } = this.state;
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            )
        }
        else {
            return (
                <>
                    <InstanceHeader />
                    {/* <div className="header pb-4" style={{background: "linear-gradient(to right, #FFFFFF, #4A002B)"}}> */}
                    <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
                        <Container fluid>
                            <div className="header-body">
                                <Row>
                                    <Col lg="8" xl="12">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            Υποβολή Αιτήματος
                                                        </span>
                                                        <CardBody>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Α/Α: &nbsp; </span> CallCenter-{this.state.instance.SerialNumber}</Row>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Ημερομηνία Υποβολής: &nbsp; </span> {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                            <Row className="align-items-center"><br></br></Row>
                                                        </CardBody>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                    <Accordion defaultActiveKey={this.state.instance.Status}>
                        {this.state.steps.map((dynamicData) =>
                            <Container className="mt-2" fluid>
                                {dynamicData.step.Status < 3 ?
                                    <Row className="mt-5">
                                        <Col className="order-xl-1" xl="12">
                                            <Card className="bg-secondary shadow">
                                                <CardHeader className="bg-white border-0">
                                                    <Accordion.Toggle as={CardHeader} eventKey={dynamicData.step.Status}>
                                                        <Row className="align-items-center">
                                                            <Col xs="8">
                                                                <h3 className="mb-0">{dynamicData.step.Status}.
                                                                    {dynamicData.step.Name === 'Επεξεργασία Αιτήματος' ? 'Καταχώρηση επικοινωνίας με καταναλωτή' : dynamicData.step.Name}
                                                                </h3>
                                                                {dynamicData.step.Status < this.state.instance.Status ?
                                                                    <>
                                                                        <p>Υποβλήθηκε {Moment(dynamicData.actions[0].data.DateCreated, "YYYYMMDDhhmm").fromNow()}</p>
                                                                    </> : <></>
                                                                }
                                                            </Col>
                                                            {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                <>
                                                                    {dynamicData.step.Status == this.state.instance.Status ?

                                                                        <>
                                                                            <Modal isOpen={this.state.showModal} toggle={this.handleJustCloseModal} className="modal-90w" fade={false}>
                                                                                <ModalHeader toggle={this.handleJustCloseModal}></ModalHeader>
                                                                                <ModalBody>
                                                                                    <div>
                                                                                        <p>
                                                                                            Μόλις επιλέξατε να απορρίψετε την αναφορά με Α/Α: {this.state.instance.SerialNumber} Παρακαλούμε όπως γίνει καταγραφή της αιτίας που οδήγησε στην απόρριψη της αναφοράς, προς έλεγχο της Αρχής και ενημέρωση του Καταναλωτή
                                                                                        </p>
                                                                                        <textarea style={{ width: '450px' }} value={declineMessage} onChange={this.handleMessageChange} />
                                                                                    </div>
                                                                                </ModalBody>
                                                                                <ModalFooter>
                                                                                    <Button disabled={!this.state.declineMessage.trim()} color="primary" onClick={() => { this.declineInstance(dynamicData.step.Status) }}>
                                                                                        Συνέχεια
                                                                                    </Button>
                                                                                </ModalFooter>
                                                                            </Modal>
                                                                            <Col className="text-right" xs="4">
                                                                                {dynamicData.step.Status == 1 ?
                                                                                    <React.Fragment>
                                                                                        <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative1'} aria-hidden="true"></i>
                                                                                        <ReactTooltip id={'info-form-control-alternative1'} type='info'>
                                                                                            <span>Δίνεται η δυνατότητα απόρριψης αιτήματος όταν:</span>
                                                                                            <li>Τα στοιχεία πελάτη είναι λάθος</li>
                                                                                            <li>Τα κοινοποιημένα έγγραφα είναι λανθασμένα</li>
                                                                                            <li>Έχουν αποσταλεί έγγραφα που τυχόν περιέχουν ευαίσθητα προσωπικά δεδομένα τα οποία δεν είναι δεκτικά επεξεργασίας.</li>
                                                                                        </ReactTooltip>
                                                                                        <Button
                                                                                            color="danger"
                                                                                            onClick={this.handleShowModal}
                                                                                            size="sm"
                                                                                            style={{ marginLeft: '10px', marginTop: '-5px' }}
                                                                                        >
                                                                                            Απευθείας Απόρριψη
                                                                                        </Button>

                                                                                        <Button
                                                                                            color="success"
                                                                                            onClick={() => { this.approveInstance(dynamicData.step.Status) }}
                                                                                            size="sm"
                                                                                        >
                                                                                            Αποδοχή και συνέχεια στο βήμα 2
                                                                                        </Button>
                                                                                    </React.Fragment>

                                                                                    : <>
                                                                                        <Button
                                                                                            id="send-answer"
                                                                                            color="success"
                                                                                            onClick={() => { this.approveInstance(dynamicData.step.Status) }}
                                                                                            size="sm"
                                                                                            disabled={this.state.commentDisabled}
                                                                                        >
                                                                                            Αποστολή απάντησης
                                                                                        </Button>
                                                                                    </>
                                                                                }
                                                                            </Col>
                                                                        </> : <> </>
                                                                    }
                                                                </> : <> </>
                                                            }
                                                        </Row>
                                                    </Accordion.Toggle>
                                                </CardHeader>
                                                <Accordion.Collapse eventKey={dynamicData.step.Status}>
                                                    <CardBody>

                                                        <Form id={dynamicData.step.Id} onSubmit={this.handleSubmit}>
                                                            <div className="pl-lg-4">
                                                                <Row>
                                                                    {dynamicData.actions.map((dynamicActions) =>
                                                                        <>
                                                                            <Col lg="12">
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="form-control-label"
                                                                                        htmlFor="input-username"
                                                                                    >
                                                                                        {dynamicActions.action.Name === 'Σχόλιο προς καταναλωτή' ? 'Αναφορά Επικοινωνίας' : dynamicActions.action.Name}
                                                                                    </label>
                                                                                    {dynamicActions.action.Type == 'file' ?
                                                                                        <>
                                                                                            <Row>
                                                                                                <Col lg="10">
                                                                                                    &nbsp;
                                                                                                    <label className="btn btn-primary" htmlFor={dynamicData.step.Id + "/" + dynamicActions.action.Id}>
                                                                                                        Επιλογή αρχείου
                                                                                                    </label>
                                                                                                    <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                    <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                        <span>Μέγιστο μέγεθος αρχείου 5ΜΒ.</span>
                                                                                                        <div id="imageattooltip"></div>
                                                                                                    </ReactTooltip>

                                                                                                    <Input
                                                                                                        className="form-control-alternative"
                                                                                                        // defaultValue={ dynamicActions.data.Data }
                                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                        // placeholder={dynamicActions.action.Description}
                                                                                                        style={{ visibility: "hidden" }}
                                                                                                        type={dynamicActions.action.Type}
                                                                                                        disabled={!dynamicActions.action.Required}
                                                                                                        onChange={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                    />


                                                                                                    <Table className="align-items-center table-flush" responsive>
                                                                                                        <thead className="thead-light">
                                                                                                            <tr>
                                                                                                                <th scope="col">Ονομα Αρχειου</th>
                                                                                                                <th scope="col">Ημερομηνια Υποβολης</th>
                                                                                                                <th scope="col">Ενεργειες</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                this.state.files.map((dynamicFiles) =>
                                                                                                                    <>
                                                                                                                        {dynamicActions.action.Id == dynamicFiles.ActionId
                                                                                                                            ?
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>{dynamicFiles.FileName}</td>
                                                                                                                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                                                                                                    <td>
                                                                                                                                        <Button
                                                                                                                                            color="primary"
                                                                                                                                            href={dynamicFiles.Link}
                                                                                                                                            target="_blank"
                                                                                                                                            size="sm"
                                                                                                                                            title="Προβολή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-eye" />
                                                                                                                                        </Button>
                                                                                                                                        <Button
                                                                                                                                            color="danger"
                                                                                                                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                                                                            size="sm"
                                                                                                                                            title="Διαγραφή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-trash" />
                                                                                                                                        </Button>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </>
                                                                                                                            : <></>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )

                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                        : <>{dynamicActions.action.Type == 'button' ?
                                                                                            <>
                                                                                                {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                                                    <>
                                                                                                        <Col lg="6">
                                                                                                            <Button
                                                                                                                color="primary"
                                                                                                                onClick={evt => this.dynamicCall(dynamicActions.action.Target)}
                                                                                                                size="sm"
                                                                                                            >
                                                                                                                {dynamicActions.action.Description}
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                        {this.state.buttonCondition == dynamicData.step.Status
                                                                                                            ?
                                                                                                            <p>{this.state.buttonMessage}</p>
                                                                                                            : <> {this.state.buttonCondition == 5 ? <p>{this.state.buttonMessage}</p> : <></>} </>
                                                                                                        }
                                                                                                    </> : <> </>
                                                                                                }
                                                                                            </>
                                                                                            : <>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                    placeholder={dynamicActions.action.Description === 'Σχόλιο προς καταναλωτή' ? 'Ενημερώστε την ΡΑΕ για την επικοινωνία σας με τον καταναλωτή' : dynamicActions.action.Description}
                                                                                                    type={dynamicActions.action.Type}
                                                                                                    disabled={dynamicActions.action.Description == 'Σχόλιο προς καταναλωτή' ? false : true}
                                                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                        </>
                                                                                    }
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        </Form>
                                                    </CardBody>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Col>
                                    </Row> : <> </>}
                            </Container>
                        )}
                    </Accordion>
                </>
            );
        }
    }
}

export default CallCenterInstance;
