// YearlyReport.js
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button
} from "reactstrap";
import WorkflowBarChart from './WorkflowBarChart';
import GenericHeader from "components/Headers/GenericHeader.js";
import background from "../../assets/img/header-background.jpg";
import InstanceHeader from "components/Headers/InstanceHeader.js";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function YearlyReport() {
    const [year, setYear] = useState(new Date().getFullYear()); // set current year as default
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
    const [allSuppliers, setAllSuppliers] = useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [user, setUser] = useState(null);


    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
    const toggleSecondDropdown = () => setSecondDropdownOpen(prevState => !prevState);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2021 + 1 }, (_, i) => currentYear - i); // assuming 2021 as starting year

    useEffect(() => {
        async function fetchUser() {
            try {
                const response = await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                });
                const result = await response.json();
                setUser(result);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }

        fetchUser();
    }, []);


    useEffect(() => {
        async function fetchSuppliers() {
            try {
                const res = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/lists/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                });

                console.log(res);

                let all = res.data.all
                let filteredAllSuppliers = []
                all.forEach(function (element, index) {
                    filteredAllSuppliers.push({
                        value: element.Name,
                        label: element.DisplayName
                    });
                });

                let filteredALL = filteredAllSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')

                let uniqueAll = [...new Set(filteredALL.map(({ value }) => value))].map(e => filteredAllSuppliers.find(({ value }) => value == e));

                console.log(uniqueAll);

                setAllSuppliers(uniqueAll);

            } catch (error) {
                console.error("Error fetching suppliers:", error);
            }
        }

        fetchSuppliers();
    }, []);

    function handleSupplierChange(supplierValue) {
        setSelectedSuppliers(prev => {
            if (prev.includes(supplierValue)) {
                // If already selected, remove from the array
                return prev.filter(value => value !== supplierValue);
            } else {
                // If not selected, add to the array
                return [...prev, supplierValue];
            }
        });
        console.log(selectedSuppliers)
    }

    function downloadYearlyReport() {
        let url = `${process.env.REACT_APP_API_LINK}/api/parameter_stats/workflows_by_month_xlsx/${year}`;
        if (selectedSuppliers.length > 0) {
            url += `?suppliers=${selectedSuppliers.join(",")}`;
        }

        fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `Report_${year}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error("Error downloading the file:", error);
            });
    }


    return (
        <>
            <InstanceHeader />
            <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <span className="h2 font-weight-bold mb-0">
                                        {selectedSuppliers.length > 0
                                            ? `Στατιστικά έτους ${year} για Προμηθευτές ${selectedSuppliers}`
                                            : `Στατιστικά έτους ${year}`
                                        }
                                    </span>

                                </CardHeader>
                                <CardBody>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ display: 'inline-block' }}>
                                            <DropdownToggle caret>
                                                Επιλέξτε Χρόνο
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {years.map(y => (
                                                    <DropdownItem key={y} onClick={() => setYear(y)}>{y}</DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                        {user && user.PersonRole === 'Admin' && (
                                            <Dropdown isOpen={secondDropdownOpen} toggle={toggleSecondDropdown} style={{ display: 'inline-block' }}>
                                                <DropdownToggle caret>
                                                    Επιλέξτε Προμηθευτές
                                                </DropdownToggle>
                                                <DropdownMenu style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                    {allSuppliers.map(supplier => (
                                                        <DropdownItem key={supplier.value} toggle={false}>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedSuppliers.includes(supplier.value)}
                                                                onChange={() => handleSupplierChange(supplier.value)}
                                                            />
                                                            {supplier.label}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        )}
                                        <Button
                                            color="success"
                                            onClick={downloadYearlyReport}
                                        >
                                            Εξαγωγή σε EXCEL
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <br />

            <Container fluid>
                <WorkflowBarChart year={year} selectedSuppliers={selectedSuppliers} />

            </Container>
        </>
    );
}

export default YearlyReport;