import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { saveAs } from 'file-saver';

import React, { useState } from "react";

import {
  Card,
  Form,
  FormGroup,
  Input,
  Table,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

import ReactTooltip from 'react-tooltip';

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from 'react-loadingg';
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import background from "../../assets/img/header-background.jpg";

import axios from 'axios';

class InstanceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      step: 0,
      details: [],
      instance: [],
      workflow: [],
      fileUpload: null,
      steps: [],
      user: [],
      files: [],
      consumerFiles: [],
      receiverFiles: [],
      replies: [],
      extra: [],
      answerStep: '',
      answerAction: '',
      answer: '',
      files: [],
      showAnswer: 0,
      hasReplies: false,
      loading: true,
      commentDisabled: true,
      file: null,
      declineMessage: '',
      declineDate: '',
      userWhoDeclined: ''
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem('token')) {
      const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/details/" + handle + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          this.setState({
            instance: res.data,
            details: res.data
          });



          if (this.state.instance.Status > 0)
            this.setState({
              step: this.state.instance.Status - 1
            });
          else {
            this.setState({
              step: 3
            });
          }
        });

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/extra/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              extra: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              user: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/replies/all/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              replies: result.replies,
              hasReplies: result.hasReplies
            });

            if (result.hasReplies) {
              var counter = 0;
              result.replies.forEach(function (arrayItem) {
                arrayItem.user = result.users[counter];
                counter++;

              });
              this.setState({
                replies: result.replies,
              });

            }
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/get_decline/" + handle + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            const declineDateString = new Date(result.Decline.DateCreated)
            this.setState({
              declineMessage: result.Decline.Message,
              declineDate: declineDateString.toLocaleDateString(),
              userWhoDeclined: result.User
            });
          }
        )
        .catch((error) => {
          console.error('Error:', error);
        });

      await fetch(process.env.REACT_APP_API_LINK + "/api/files/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              files: result,
              loading: false
            });
            let allFiles = this.state.files
            let filteredConsumerFiles = allFiles.filter(element => {
              if (element.UserId == this.state.instance.UserId) return element
            })
            let filteredReceiverFiles = allFiles.filter(element => {
              if (element.UserId != this.state.instance.UserId) return element
            })
            this.setState({
              consumerFiles: filteredConsumerFiles,
              receiverFiles: filteredReceiverFiles
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )
    };
  }

  downloadFiles = () => {
    if (localStorage.getItem('token')) {
      this.setState({ isLoading: true });

      fetch(process.env.REACT_APP_API_LINK + "/api/files/download_files/" + this.state.instance.Id + '/', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }
          return response.blob();
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'files.zip');
          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        })
        .catch(error => console.error(error))
    }
  }

  downloadAllFiles = (files) => {
    files.forEach(f => {
      fetch(f.Link, {
        headers: {
          'Content-Type': f.Type
        }
      })
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, f.FileName);
        });
    })
  };

  handleDownload = (link, contentType, name) => {
    fetch(link, {
      headers: {
        'Content-Type': contentType
      }
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, name);
      });
  };

  GiveAnswer = stepStatus => {
    this.setState({
      showAnswer: 1
    });
  }

  updateAnswer(evt) {
    if (evt.target.value != '') {
      this.setState({ commentDisabled: false })
    }
    else this.setState({ commentDisabled: true })

    this.setState({
      answer: evt.target.value
    })
  }

  sendAnswer(evt) {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/replies/new/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'InstanceId': this.state.instance.Id,
          'UserType': 1,
          'Message': this.state.answer
        })
      })
        .then(data => {
          fetch(process.env.REACT_APP_API_LINK + "/api/replies/all/" + this.state.instance.Id + "/", {
            method: 'get',
            headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
            .then(res => res.json())
            .then(
              (result) => {
                this.setState({
                  replies: result.replies,
                  hasReplies: true,
                  showAnswer: 0
                });

                var counter = 0;
                result.replies.forEach(function (arrayItem) {
                  arrayItem.user = result.users[counter];
                  counter++;

                });
                this.setState({
                  replies: result.replies,
                });

              },
              (error) => {
                this.setState({
                  error
                });
              }
            )
        })
    }
  }

  restoreInstance(instance_id) {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/restore/' + instance_id + '/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            window.location.reload(false)
          }
        )

    }
  }

  acceptDeclinedInstance(instance_id) {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/accept_decline/' + instance_id + '/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            window.location.reload(false)
          }
        )

    }
  }

  updateFile(evt) {
    this.setState({ fileUpload: evt.target.files[0] });
  }

  deleteFile = fileId => {
    if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε το συγκεκριμένο αρχείο;") == true) {
      if (localStorage.getItem('token')) {
        axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
          .then(res => {
            axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
              .then(res => {
                this.setState({
                  loading: false,
                  files: res.data
                });
                window.location.reload(false)
              })
          })
      }
    }
    else { }
  }

  uploadFile(evt) {
    const formData = new FormData();

    // Update the formData object 
    formData.append(
      "files",
      evt.target.files[0]
    );

    fetch(process.env.REACT_APP_API_LINK + "/api/files/answer/" + this.state.instance.Id + "/", {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then((result) => {
        this.setState({
          answerStep: result.step,
          answerAction: result.action
        });

        fetch(
          process.env.REACT_APP_FILEMANAGER_LINK,
          {
            method: 'POST',
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((result) => {

            fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
              method: 'POST',
              headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
              }),
              body: JSON.stringify({
                'Name': result[0].name,
                'Path': result[0].path,
                'Size': result[0].size,
                'Type': result[0].type,
                'InstanceId': this.state.instance.Id,
                'StepId': this.state.answerStep,
                'ActionId': this.state.answerAction
              })
            })
              .then(data => {

                axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                  .then(res => {
                    this.setState({
                      loading: false,
                      files: res.data
                    });
                    let allFiles = this.state.files
                    let filteredConsumerFiles = allFiles.filter(element => {
                      if (element.UserId == this.state.instance.UserId) return element
                    })
                    let filteredReceiverFiles = allFiles.filter(element => {
                      if (element.UserId != this.state.instance.UserId) return element
                    })
                    this.setState({
                      consumerFiles: filteredConsumerFiles,
                      receiverFiles: filteredReceiverFiles
                    });
                  })

                fetch(process.env.REACT_APP_API_LINK + "/api/files/instance/" + this.state.instance.Id + '/', {
                  method: 'get',
                  headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                  })
                })
                  .then(res => res.json())
                  .then(
                    (result) => {
                      this.setState({
                        files: result,
                        loading: false
                      });
                      let allFiles = this.state.files
                      let filteredConsumerFiles = allFiles.filter(element => {
                        if (element.UserId == this.state.instance.UserId) return element
                      })
                      let filteredReceiverFiles = allFiles.filter(element => {
                        if (element.UserId != this.state.instance.UserId) return element
                      })
                      this.setState({
                        consumerFiles: filteredConsumerFiles,
                        receiverFiles: filteredReceiverFiles
                      });
                    },
                    (error) => {
                      this.setState({
                        error
                      });
                    }
                  )
              })
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      });
  }


  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <InstanceHeader />
          <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Υποβολή Αιτήματος
                            </span>
                            <CardBody>
                              <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Αριθμός Αιτήματος: </span> &nbsp; {this.state.instance.SerialNumber}</Row>
                              <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Ημερομηνία Έναρξης: </span> &nbsp; {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                            </CardBody>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className="mt--4" fluid>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Πορεία Αιτήματος</h3>
                      </Col>
                      {this.state.instance.Status < 3 ?
                        <Col className="text-right" xs="6">
                          <Button
                            color="success"
                            href={"/admin/instance/" + this.state.instance.Id}
                            size="sm"
                          >
                            Διαχείριση Αιτήματος
                          </Button>
                          <Button
                            color="warning"
                            href={"/admin/instancexport/" + this.state.instance.Id}
                            size="sm"
                          >
                            Εξαγωγή Αιτήματος
                          </Button>
                          {this.state.user.Photo == 'rae' ?
                            <>
                              <Button
                                color="primary"
                                // onClick={() => { this.recallStep() }}
                                size="sm"
                              >
                                Ρυθμίσεις
                              </Button>
                              {/* <Button
                                    color="danger"
                                    // onClick={() => { this.recallStep() }}
                                    size="sm"
                                  >
                                    Ανάκληση πρόσφατου βήματος
                                  </Button> */}
                            </>
                            : <> </>}
                        </Col>
                        : <>
                          <Col className="text-right" xs="6">
                            <Button
                              color="warning"
                              href={"/admin/instancexport/" + this.state.instance.Id}
                              size="sm"
                            >
                              Εξαγωγή Αιτήματος
                            </Button>
                            {/* <Button
                              color="info"
                              onClick={this.downloadFiles}
                              size="sm"
                            >
                              Κατεβασμα Αιτήματος
                            </Button> */}
                            {(this.state.instance.Status == 4 && this.state.user.PersonRole == 'Admin') &&
                              <>
                                <Button
                                  color="success"
                                  onClick={() => this.restoreInstance(this.state.instance.Id)}
                                  size="sm"
                                >
                                  Επαναφορά Αιτήματος
                                </Button>
                              </>
                            }
                            {(this.state.instance.Status == 4 && this.state.user.PersonRole == 'Admin' && this.state.instance.Completed == false) &&
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => this.acceptDeclinedInstance(this.state.instance.Id)}
                                  size="sm"
                                >
                                  Αποδοχή Απόρριψης
                                </Button>
                              </>
                            }
                          </Col>
                        </>}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.instance.Status < 4 ?
                      <>
                        <div>
                          <Steps current={this.state.step}>
                            <Steps.Item title="Υποβολή Αιτήματος" description="Το αίτημα υποβλήθηκε." />
                            <Steps.Item title="Διαχείριση αιτήματος" description="Λήψη και έλεγχος του αιτήματός σας." />
                            <Steps.Item title="Επεξεργασία του αιτήματος" description="Αίτημα σε επεξεργασία" />
                          </Steps>
                        </div>
                      </>
                      : <>
                        <div>
                          <Steps current={this.state.step}>
                            <Steps.Item title="Υποβολή Αιτήματος" description="Το αίτημα υποβλήθηκε." />
                            <Steps.Item title="Διαχείριση αιτήματος" description="Έλεγχος και επεξεργασία του αιτήματός σας." />
                            <Steps.Item title="Ολοκλήρωση του αιτήματος" description="Αίτημα ολοκληρωμένο" />
                          </Steps>
                        </div>
                      </>}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col className="order-xl-1" xl={this.state.instance.Status > 2 ? '6' : '12'}>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Αίτησης</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* <Button
                            color="primary"
                            href={"/admin/instance/" + this.state.details.Id}
                            size="sm"
                          >
                            Βήματα αιτήματος
                          </Button> */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Όνομα Κατόχου
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Όνομα Κατόχου"
                                defaultValue={this.state.extra.name}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Επίθετο Κατόχου
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Επίθετο Κατόχου"
                                defaultValue={this.state.extra.last}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                ΑΦΜ Κατόχου
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="ΑΦΜ Κατόχου"
                                defaultValue={this.state.extra.afm}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Αριθμός Παροχής
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Αριθμός Παροχής"
                                defaultValue={this.state.extra.num}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατηγορία Αιτήματος
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.extra.category}
                                placeholder="Κατηγορία"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Είδος Ενέργειας
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Είδος Ενέργειας"
                                defaultValue={this.state.extra.energy}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Είδος Παραλήπτη
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Είδος Παραλήπτη"
                                defaultValue={this.state.extra.typercvr}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Παραλήπτης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Παραλήπτης"
                                defaultValue={this.state.extra.rcvr}
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατάσταση Αιτήματος
                              </label>
                              {this.state.details.Status == 1 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='1. Υποβολή Αιτήματος'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 2 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='2. Επεξεργασία Αιτήματος'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 3 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='3. Αίτημα προς Ολοκλήρωση'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 4 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='4. Απόρριψη Αίτησης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                              {this.state.details.Status == 5 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='5. Ολοκλήρωση Αίτησης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                  disabled={true}
                                /> : <> </>}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <h6 className="heading-small text-muted mb-4">
                              Σχολια/Παρατηρησεις Αιτηματος
                            </h6>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={this.state.extra.text}
                              placeholder="Σχόλια/Παρατηρήσεις Αιτήματος"
                              type="textarea"
                              disabled={true}
                            />
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Εγγραφα Καταναλωτη
                            </h6>
                          </Col>
                          {this.state.consumerFiles.length > 0 &&
                            <>
                              <Col md="6">
                                <Button
                                  color="success"
                                  size='sm'
                                  onClick={() => { this.downloadAllFiles(this.state.consumerFiles) }}
                                >
                                  Λήψη όλων
                                </Button>
                              </Col>
                            </>}

                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Ονομα αρχειου</th>
                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                <th scope="col">Ενεργειες</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.consumerFiles.map((dynamicFiles) =>
                                  <tr>
                                    <td>{dynamicFiles.FileName}</td>
                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                    <td>
                                      <Button
                                        color="primary"
                                        href={dynamicFiles.Link}
                                        target="_blank"
                                        title="Προβολή"
                                        className="btn-sm btn-primary"
                                      >
                                        <i className="fas fa-eye" />
                                      </Button>
                                      <Button
                                        onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                        color="success"
                                        size='sm'
                                        title="Λήψη"
                                      >
                                        <i className="fas fa-download" />
                                      </Button>
                                      {this.state.user.PersonRole == 'Admin' ?
                                        <>
                                          <Button
                                            color="danger"
                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                            size="sm"
                                            title="Διαγραφή"
                                          >
                                            <i className="fas fa-trash" />
                                          </Button>
                                        </> : <> </>}
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </Table>
                        </Row>
                      </div>

                      {/* {this.state.user.Photo == 'rae' ?
                        <>
                          <hr className="my-4" />
                          <div className="pl-lg-4">
                            <Row>
                              <Col md="12" >
                                <h6 className="heading-small text-muted mb-4">
                                  Στοιχεια συμβεβλημενων προσωπων
                                </h6>

                                <Button
                                  color="primary"
                                  // href={"/admin/consumer/" + this.state.details.UserId}
                                  size="sm"
                                >
                                  Στοιχεία Καταναλωτή
                                </Button>
                                <Button
                                  color="primary"
                                  // href={"/admin/consumer/" + this.state.details.UserId}
                                  size="sm"
                                >
                                  Στοιχεία Παραλήπτη
                                </Button>


                              </Col>
                            </Row>
                          </div> </> : <> </>} */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>

              {(this.state.instance.Status == 4 && this.state.user.PersonRole == 'Admin') ?
                <>
                  <Col className="order-xl-1" xl="6">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Αιτία απόρριψης αιτήματος</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Col md="12">
                          <h6 className="heading-small text-muted mb-2">
                            Απαντηση Προμηθευτη / Διαχειριστη ({this.state.declineDate} / {this.state.userWhoDeclined})
                          </h6>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={this.state.declineMessage}
                            placeholder="Απάντηση Παραλήπτη"
                            type="textarea"
                            disabled={true}
                          />
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </> : <></>}

              {this.state.instance.Status != 4 && this.state.instance.Status >= 3 ?
                <Col className="order-xl-1" xl="6">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Έκβαση αιτήματος</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Col md="12">
                        <h6 className="heading-small text-muted mb-2">
                          Απαντηση Προμηθευτη / Διαχειριστη ({this.state.extra.date})
                        </h6>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          defaultValue={this.state.extra.reply}
                          placeholder="Απάντηση Παραλήπτη"
                          type="textarea"
                          disabled={true}
                        />
                      </Col>
                      <br />

                      {this.state.hasReplies &&
                        <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                              <Col xs="8">
                                <h4 className="mb-0">Ανταλλαγή Μηνυμάτων</h4>
                              </Col>
                              <Col className="text-right" xs="4">
                                {/* <Button
                                      color="primary"
                                      href={"/admin/instance/" + this.state.details.Id}
                                      size="sm"
                                    >
                                      Βήματα αιτήματος
                                    </Button> */}
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Col md="12" style={{ "height": "38vh", "overflowY": "scroll" }}>
                              {this.state.replies.map((dynamicReplies) =>
                                <div class="container" style={{ textAlign: dynamicReplies.UserType == 1 ? "right" : "left", background: dynamicReplies.UserType == 1 ? "powderblue" : "lightgray  ", color: "black", borderRadius: "8px" }}>
                                  <strong style={{ margin: "0px" }} class={dynamicReplies.UserType == 1 ? "time-right" : "time-left"}>{dynamicReplies.user}</strong>
                                  <p style={{ margin: "0px" }} class={dynamicReplies.UserType == 1 ? "time-right" : "time-left"}>{Moment(dynamicReplies.DateCreated).locale('el').format('DD MMM YYYY HH:mm:ss')}</p>
                                  <h4><i>{dynamicReplies.Message}</i></h4>
                                </div>
                              )}
                            </Col>
                          </CardBody>
                        </Card>}

                      <br />

                      {this.state.instance.Status >= 4 ?
                        <> </>
                        :
                        <Row className="align-items-center">
                          <div className="pl-lg-4" style={{ width: '100%' }}>
                            <h6 className="heading-small text-muted mb-4">
                              Νεο Σχολιο
                            </h6>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Σχόλιο"
                              type="textarea"
                              onChange={evt => this.updateAnswer(evt)}
                            />
                            <br />
                            <Col lg="10">
                              <label className="btn-sm btn-primary" htmlFor='file-answer' >
                                Επιλογή αρχείου
                              </label>
                              <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative'} aria-hidden="true" style={{ marginLeft: '5px', marginTop: '3px' }}></i>
                              <Input
                                className="form-control-alternative"
                                id='file-answer'
                                style={{ visibility: "hidden" }}
                                type='file'
                                onChange={evt => this.uploadFile(evt)}
                              />


                              <ReactTooltip id={'info-form-control-alternative'} type='info'>
                                <span>Μέγιστο μέγεθος αρχείο 5ΜΒ.</span>
                                <div id="imageattooltip"></div>
                              </ReactTooltip>
                              {/* <Button
                                      color="primary"
                                      onClick={evt => this.uploadFile()}
                                      size="sm"
                                    >
                                      Επισύναψη
                                    </Button> */}
                            </Col>
                            <br />
                            <Button
                              color="success"
                              onClick={evt => this.sendAnswer()}
                              size="sm"
                              style={{ width: '100%' }}
                              disabled={this.state.commentDisabled}
                            >
                              Αποστολή
                            </Button>
                          </div>

                          {/* <Col md="12">
                                <Button
                                  // href="/admin/instancesettings"
                                  // style={{ backgroundColor: this.state.yellow }}
                                  // color={this.state.yellow}
                                  color='success'
                                  size="sm"
                                >
                                  Αποδοχή απάντησης
                                </Button>

                                <Button
                                  // href="/admin/instancesettings"
                                  // style={{ backgroundColor: this.state.yellow }}
                                  // color={this.state.yellow}
                                  color='danger'
                                  size="sm"
                                  onClick={() => { this.GiveAnswer() }}
                                >
                                  Απόρριψη Απάντησης
                                </Button>
                              </Col> */}
                        </Row>}
                      <br />
                      <br />
                      <div className="pl-lg-4" style={{ width: '100%' }}>
                        <Row>
                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Εγγραφα Παραληπτη
                            </h6>
                          </Col>
                          {this.state.receiverFiles.length > 1 &&
                            <>
                              <Col md="6">
                                <Button
                                  color="success"
                                  size='sm'
                                  onClick={() => { this.downloadAllFiles(this.state.receiverFiles) }}
                                >
                                  Λήψη όλων
                                </Button>
                              </Col>
                            </>}

                          <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Ονομα αρχειου</th>
                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                <th scope="col">Ενεργειες</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.receiverFiles.map((dynamicFiles) =>
                                  <tr>
                                    <td>{dynamicFiles.FileName}</td>
                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                    <td>
                                      <Button
                                        color="primary"
                                        href={dynamicFiles.Link}
                                        target="_blank"
                                        title="Προβολή"
                                        className="btn-sm btn-primary"
                                      >
                                        <i className="fas fa-eye" />
                                      </Button>
                                      <Button
                                        onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                        color="success"
                                        size='sm'
                                        title="Λήψη"
                                      >
                                        <i className="fas fa-download" />
                                      </Button>
                                      {this.state.user.PersonRole == 'Admin' ?
                                        <>
                                          <Button
                                            color="danger"
                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                            size="sm"
                                            title="Διαγραφή"
                                          >
                                            <i className="fas fa-trash" />
                                          </Button>
                                        </> : <> </>}
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </Table>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col> : <></>}
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default InstanceDetails;
