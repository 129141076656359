import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { CommonLoading } from 'react-loadingg';

import {
    Button,
    Card,
    Label,
    Form,
    Input,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

function base64toBlob(base64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

class consumerIntimeInstances extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            isLoading: false,
            instances: [],
            user: [],
            isZipChecked: false
        };

        this.toggleZipCheck = this.toggleZipCheck.bind(this);
    }

    async componentDidMount() {

        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        user: result,
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            );

        await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/consumer_intime_instances/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var myuser = this.state.user.PersonRole;
                    const instances2 = result;
                    instances2.forEach(function (part, index) {
                        this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
                        var dateArray = ((this[index].StartedAt).toString()).split('T');
                        var timeArray = dateArray[1].split(':');
                        this[index].NewSubmittedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
                        // this[index].Receiver = this[index].Receiver[0];
                        try {
                            this[index].CategoryData = this[index].CategoryData.split('/')[0];
                        } catch (error) {
                            console.error("Error occurred:", error);
                            // Handle the error or set a default value
                            this[index].CategoryData = ""; // or some default value
                        }
                        this[index].DurationWaiting = this[index].DurationWaiting != null ? this[index].DurationWaiting + ' ημέρες' : this[index].DurationWaiting;
                        this[index].Buttons =
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    href={"/admin/details/" + this[index].Id}
                                    size="sm"
                                    title="Στοιχεία"
                                >
                                    Λεπτομέρειες
                                </Button>

                            </React.Fragment>

                    }, instances2);
                    this.datas = {
                        columns: [
                            {
                                label: 'Α/Α ▼',
                                field: 'SerialNumber',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Ενεργειες',
                                field: 'Buttons',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Κατηγορια ▼',
                                field: 'CategoryData',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Ειδος Ενεργειασ ▼',
                                field: 'ReceiverKindData',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Κατασταση ▼',
                                field: 'NewStatus',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Αναμονη ▼',
                                field: 'DurationWaiting',
                                sort: 'asc',
                                width: 50
                            },
                            {
                                label: 'Παραληπτης ▼',
                                field: 'Receiver',
                                sort: 'asc',
                                width: 150
                            }, ,
                            {
                                label: 'Ημ/νια Υποβολης ▼',
                                field: 'NewSubmittedAt',
                                sort: 'asc',
                                width: 150
                            }
                        ],
                        rows: result,
                    };
                    this.setState({
                        isLoaded: false,
                        instances: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            );


    }

    toggleZipCheck() {
        this.setState(prevState => ({ isZipChecked: !prevState.isZipChecked }));
    }

    handleClick = instanceId => {
        if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε την συγκεκριμένη αναφορά;") == true) {
            if (localStorage.getItem('token')) {
                axios.delete(process.env.REACT_APP_API_LINK + '/api/workflowinstances/' + instanceId + "/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                    .then(res => {
                        window.location.reload(false);
                    })
            }
        }
        else { }
    }

    ExportToExcel = () => {
        if (localStorage.getItem('token')) {
            this.setState({ isLoading: true });

            fetch(process.env.REACT_APP_API_LINK + '/api/export/parameterexportexcel/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'responseType': 'blob'
                }),
                body: JSON.stringify({
                    'startDate': "-",
                    'endDate': "-",
                    'selectedSupplierMaintainer': "-",
                    'selectedComplaint': "ΟΛΑ",
                    'receiverKind': "ΟΛΑ",
                    'receiverName': "ΟΛΑ",
                    'energyKind': "ΟΛΑ",
                    'activeComplaints': "1",
                    'durationWaiting': "30-",
                    'lastReplyBy': 1,
                    'isZipChecked': this.state.isZipChecked
                })
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    // Create Blob from base64 Excel file
                    const excelBlob = base64toBlob(data.file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    var excelUrl = URL.createObjectURL(excelBlob);
                    const excelLink = document.createElement('a');
                    excelLink.href = excelUrl;
                    excelLink.setAttribute('download', `Αιτήματα Παραπόνων.xlsx`);
                    document.body.appendChild(excelLink);
                    excelLink.click();
                    excelLink.parentNode.removeChild(excelLink);

                    if (this.state.isZipChecked) {
                        const zipBlob = base64toBlob(data.zipFile, 'application/zip');
                        var zipUrl = URL.createObjectURL(zipBlob);
                        const zipLink = document.createElement('a');
                        zipLink.href = zipUrl;
                        zipLink.setAttribute('download', `files.zip`);
                        document.body.appendChild(zipLink);
                        zipLink.click();
                        zipLink.parentNode.removeChild(zipLink);
                    }
                })
                .finally(() => {
                    this.setState({ isLoading: false });
                });

        }
    }

    render() {
        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--8" fluid>
                    {this.state.isLoaded ?
                        <>
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <br /><br />
                            <CommonLoading />
                        </>
                        :
                        <>
                            <Row className="mt-5">
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">Αιτήματα Παραπόνων</h3>
                                                </Col>
                                                <Col className="text-right" xs="4">
                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        onClick={this.ExportToExcel}
                                                    >
                                                        {this.state.isLoading ? 'Περιμένετε...' : 'Εξαγωγή Αιτήσεων'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col xs="12" className="d-flex justify-content-end">
                                                    <Form>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={this.state.isZipChecked}
                                                                onChange={this.toggleZipCheck}
                                                            />{' '}
                                                            Κατέβασμα αρχείων αιτήσεων
                                                        </Label>
                                                    </Form>
                                                </Col>
                                            </Row> */}

                                        </CardHeader>

                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <MDBDataTable
                                                        bordered
                                                        exportToCSV
                                                        hover
                                                        strip
                                                        responsive
                                                        data={this.datas}
                                                        id="mdbdttable"
                                                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                        noDataComponent="Δεν υπάρχουν δεδομένα"
                                                        searchLabel="Αναζήτηση"
                                                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                                    />
                                                </div>

                                            </Row>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>
                        </>}
                </Container>
            </>
        );
    }
}

export default consumerIntimeInstances;
