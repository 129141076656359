/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from 'axios';
import Select from 'react-select';

class NewCallCenterUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      roles: [],
      role: '',
      name: '',
      email: '',
      created: false,
      user: [],
      newAdmin: [],
      receiverName: '',
      allSuppliers: [],
      isOpenDanger: false
    };
    this.changeReceiverName = this.changeReceiverName.bind(this);
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result.PersonRole != 'Admin' && result.PersonRole != 'Head User') {
              this.props.history.goBack();
            }
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };
    const result = axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/lists/", {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
      .then(
        (res) => {

          let all = res.data.all
          let filteredAllSuppliers = []
          all.forEach(function (element, index) {
            filteredAllSuppliers.push({
              value: element.Name,
              label: element.DisplayName
            });
          });
          // var filteredALL = filteredAllSuppliers.filter(e => e['value'] !== 'Δοκιμαστικός Παραλήπτης')
          let uniqueAll = [...new Set(filteredAllSuppliers.map(({ value }) => value))].map(e => filteredAllSuppliers.find(({ value }) => value == e));

          this.setState({
            allSuppliers: uniqueAll
          });
          console.log(this.state.allSuppliers);
        },
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  onDismissDanger = () => {
    this.setState({
      error: null,
      activeNav: 1,
      roles: [],
      role: '',
      name: '',
      email: '',
      created: false,
      newAdmin: [],
      receiverName: '',
      allSuppliers: [],
      isOpenDanger: false
    });
  }

  createUser = async userId => {
    if (localStorage.getItem('token')) {
      let result = await fetch(process.env.REACT_APP_API_LINK + '/api/user/callcenter/new/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'DisplayName': this.state.name,
          'Email': this.state.email,
          'Roles': 'Admin'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result[0] == -1) {
              this.setState({
                isOpenDanger: true
              });
            }
            else {
              window.location.reload();
            }
          }
        )
    }
  }

  updateName(evt) {
    this.setState({
      name: evt.target.value
    })
  }

  updateRole = (role) => {
    this.setState({
      role: role['value'],
    })
  }

  updateEmail(evt) {
    this.setState({
      email: evt.target.value
    })
  }

  changeReceiverName = (receiverName) => {
    this.setState({ receiverName: receiverName['value'] }, () =>

      console.log(`Receiver Name selected:`, this.state.receiverName)
    );
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <br />
          <UncontrolledAlert color="danger" toggle={this.onDismissDanger} isOpen={this.state.isOpenDanger} fade={false}>
            <span className="alert-inner--text">
              Υπάρχει ήδη χρήστης με το συγκεκριμένο email!
            </span>
          </UncontrolledAlert>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Δημιουργία νέου χρήστη</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        disabled={!(this.state.name != '' && this.state.email != '')}
                        color="primary"
                        onClick={() => { this.createUser() }}
                        size="sm"
                      >
                        Δημιουργία
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ονοματεπώνυμο
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder=""
                              type="email"
                              onChange={evt => this.updateEmail(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewCallCenterUser;
