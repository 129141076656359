import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { Bar, Line } from 'react-chartjs-2';

const barChartOptions = {
    plugins: {
        labels: {
            render: 'value', // This will display the actual value, not the percentage
            fontStyle: 'bold',
            position: 'outside',
            textMargin: 6
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function (value) {
                    return value; // Ensure Y axis ticks are displayed as absolute values
                }
            }
        }]
    }
};

const lineChartOptions = {
    tooltips: {
        mode: 'index',
        intersect: false,
    },
    responsive: true,
    scales: {
        xAxes: [{
            display: true,
            scaleLabel: {
                display: true,
                labelString: 'Μήνες'
            }
        }],
        yAxes: [{
            display: true,
            scaleLabel: {
                display: true,
                labelString: 'Αναφορές'
            },
            ticks: {
                beginAtZero: true,
                callback: function (value) {
                    return value;
                }
            }
        }]
    },
    animation: {
        duration: 1,
        onComplete: function () {
            var chartInstance = this.chart,
                ctx = chartInstance.ctx;

            ctx.font = chartInstance.config.options.defaultFontFamily;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = "#666";

            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            });
        }

    }
};



function WorkflowBarChart({ year, selectedSuppliers }) {

    const barChartRef = useRef(null);
    const lineChartByKindRef = useRef(null);
    const lineChartByNameRef = useRef(null);
    const lineChartByEnergyKindRef = useRef(null);
    const lineChartByCategoryRef = useRef(null);
    const lineChartByDurationRef = useRef(null);
    const [lineChartByKindOptions, setLineChartByKindOptions] = useState(lineChartOptions);
    const [lineChartByNameOptions, setLineChartByNameOptions] = useState(lineChartOptions);
    const [lineChartByEnergyKindOptions, setLineChartByEnergyKindOptions] = useState(lineChartOptions);
    const [lineChartByCategoryOptions, setLineChartByCategoryOptions] = useState(lineChartOptions);
    const [lineChartByDurationOptions, setLineChartByDurationOptions] = useState(lineChartOptions);



    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                if (localStorage.getItem('token')) {
                    // Construct the URL with conditional supplier parameter
                    let endpointUrl = `${process.env.REACT_APP_API_LINK}/api/parameter_stats/workflows_by_month/${year}`;
                    if (selectedSuppliers.length > 0) {
                        endpointUrl += `?suppliers=${selectedSuppliers.join(",")}`;
                    }

                    const response = await fetch(endpointUrl, {
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        })
                    });

                    // Get the JSON data from the response
                    const data = await response.json();

                    const barChartData = {
                        labels: data.labels,
                        datasets: [{
                            label: 'Κατανομή Παραπόνων ανά Μήνα',
                            data: data.data,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1
                        }]
                    };

                    const lineChartDataByKind = {
                        labels: data.labels,
                        datasets: Object.keys(data.data_by_kind).map(kind => {
                            return {
                                label: kind,
                                data: data.data_by_kind[kind],
                                fill: false,
                                borderColor: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`,
                                tension: 0.1
                            };
                        })
                    };

                    const lineChartDataByName = {
                        labels: data.labels,
                        datasets: Object.keys(data.data_by_name).map(name => {
                            return {
                                label: name,
                                data: data.data_by_name[name],
                                fill: false,
                                borderColor: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`,
                                tension: 0.1
                            };
                        })
                    };

                    const lineChartDataByEnergyKind = {
                        labels: data.labels,
                        datasets: Object.keys(data.data_by_energy_kind).map(kind => {
                            return {
                                label: kind,
                                data: data.data_by_energy_kind[kind],
                                fill: false,
                                borderColor: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`,
                                tension: 0.1
                            };
                        })
                    };

                    const lineChartDataByCategory = {
                        labels: data.labels,
                        datasets: Object.keys(data.data_by_category).map(category => {
                            return {
                                label: category,
                                data: data.data_by_category[category],
                                fill: false,
                                borderColor: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`,
                                tension: 0.1
                            };
                        })
                    };

                    const lineChartDataByDuration = {
                        labels: data.labels,
                        datasets: Object.keys(data.data_by_duration).map(durationGroup => {
                            return {
                                label: durationGroup,
                                data: data.data_by_duration[durationGroup],
                                fill: false,
                                borderColor: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`,
                                tension: 0.1
                            };
                        })
                    };


                    setChartData({
                        barChart: barChartData,
                        lineChartByKind: lineChartDataByKind,
                        lineChartByName: lineChartDataByName,
                        lineChartByEnergyKind: lineChartDataByEnergyKind,
                        lineChartByCategory: lineChartDataByCategory,
                        lineChartByDuration: lineChartDataByDuration
                    });

                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        }

        fetchData();
    }, [year, selectedSuppliers]);

    function getDefaultAnimation() {
        return {
            duration: 1,
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.font = chartInstance.config.options.defaultFontFamily;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                ctx.fillStyle = "#666";

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        };
    }

    function toggleAnimationForChart(setOptionsFunction, currentOptions) {
        let newOptions = JSON.parse(JSON.stringify(currentOptions)); // Deep clone to prevent mutation issues

        if (newOptions.animation) {
            console.log("Removing animation");
            delete newOptions.animation;
        } else {
            console.log("Adding animation");
            newOptions.animation = getDefaultAnimation();
        }

        console.log("New options:", newOptions);
        setOptionsFunction(newOptions);
    }




    function downloadBarChart() {
        const link = document.createElement('a');
        link.download = 'Μηνιαίες Αναφορές.jpeg';
        link.href = barChartRef.current.chartInstance.toBase64Image('image/png', 1);
        link.click();
    }

    function downloadLineChartByKind() {
        const link = document.createElement('a');
        link.download = 'Μηνιαίες Αναφορές ανά Είδος Εταιρείας.jpeg';
        link.href = lineChartByKindRef.current.chartInstance.toBase64Image('image/png', 1);
        link.click();
    }

    function downloadLineChartByName() {
        const link = document.createElement('a');
        link.download = 'Μηνιαίες Αναφορές ανά Εταιρεία.jpeg';
        link.href = lineChartByNameRef.current.chartInstance.toBase64Image('image/png', 1);
        link.click();
    }

    function downloadLineChartByEnergyKind() {
        const link = document.createElement('a');
        link.download = 'Μηνιαίες Αναφορές ανά Είδος Ενέργειας.jpeg';
        link.href = lineChartByEnergyKindRef.current.chartInstance.toBase64Image('image/png', 1);
        link.click();
    }

    function downloadLineChartByCategory() {
        const link = document.createElement('a');
        link.download = 'Μηνιαίες Αναφορές ανά Κατηγορία.jpeg';
        link.href = lineChartByCategoryRef.current.chartInstance.toBase64Image('image/png', 1);
        link.click();
    }

    function downloadLineChartByDuration() {
        const link = document.createElement('a');
        link.download = 'Μηνιαίες Αναφορές ανά Χρόνο Πρώτης Απάντησης.jpeg';
        link.href = lineChartByDurationRef.current.chartInstance.toBase64Image('image/png', 1);
        link.click();
    }

    function downloadAllCharts() {
        downloadBarChart();
        downloadLineChartByKind();
        downloadLineChartByName();
        downloadLineChartByEnergyKind();
        downloadLineChartByCategory();
        downloadLineChartByDuration();
    }

    return (
        <div>
            {chartData ? (
                <>
                    <Button
                        color="info"
                        onClick={downloadAllCharts}
                        size="sm"
                    >
                        Λήψη όλων των εικόνων Γραφημάτων
                    </Button>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8 col-md-8">
                                            <h3 className="mb-0">Κατανομή Παραπόνων ανά Μήνα</h3>
                                        </div>
                                        <div className="col-4 md-4 text-right">
                                            <Button
                                                color="info"
                                                onClick={downloadBarChart}
                                                size="sm"
                                            >
                                                Λήψη Εικόνας
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Bar data={chartData.barChart} options={barChartOptions} ref={barChartRef} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8 col-md-8">
                                            <h3 className="mb-0">Μηνιαία Κατανομή Παραπόνων ανά είδος Παραλήπτη</h3>
                                        </div>
                                        <div className="col-4 md-4 text-right">
                                            <Button
                                                color="info"
                                                onClick={downloadLineChartByKind}
                                                size="sm"
                                            >
                                                Λήψη Εικόνας
                                            </Button>
                                            <Button
                                                color="warning"
                                                onClick={() => toggleAnimationForChart(setLineChartByKindOptions, lineChartByKindOptions)}
                                                size="sm"
                                            >
                                                Εναλλαγή προβολής τιμών
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Line data={chartData.lineChartByKind} options={lineChartByKindOptions} ref={lineChartByKindRef} key={JSON.stringify(lineChartByKindOptions)}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8 col-md-8">
                                            <h3 className="mb-0">Μηνιαία Κατανομή Παραπόνων ανά Παραλήπτη</h3>
                                        </div>
                                        <div className="col-4 md-4 text-right">
                                            <Button
                                                color="info"
                                                onClick={downloadLineChartByName}
                                                size="sm"
                                            >
                                                Λήψη Εικόνας
                                            </Button>
                                            <Button
                                                color="warning"
                                                onClick={() => toggleAnimationForChart(setLineChartByNameOptions, lineChartByNameOptions)}
                                                size="sm"
                                            >
                                                Εναλλαγή προβολής τιμών
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Line data={chartData.lineChartByName} options={lineChartByNameOptions} ref={lineChartByNameRef} key={JSON.stringify(lineChartByNameOptions)}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8 col-md-8">
                                            <h3 className="mb-0">Μηνιαία Κατανομή Παραπόνων ανά Είδος Ενέργειας</h3>
                                        </div>
                                        <div className="col-4 md-4 text-right">
                                            <Button
                                                color="info"
                                                onClick={downloadLineChartByEnergyKind}
                                                size="sm"
                                            >
                                                Λήψη Εικόνας
                                            </Button>
                                            <Button
                                                color="warning"
                                                onClick={() => toggleAnimationForChart(setLineChartByEnergyKindOptions, lineChartByEnergyKindOptions)}
                                                size="sm"
                                            >
                                                Εναλλαγή προβολής τιμών
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Line data={chartData.lineChartByEnergyKind} options={lineChartByEnergyKindOptions} ref={lineChartByEnergyKindRef} key={JSON.stringify(lineChartByEnergyKindOptions)}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8 col-md-8">
                                            <h3 className="mb-0">Μηνιαία Κατανομή Παραπόνων ανά Κατηγορία Παραπόνων</h3>
                                        </div>
                                        <div className="col-4 md-4 text-right">
                                            <Button
                                                color="info"
                                                onClick={downloadLineChartByCategory}
                                                size="sm"
                                            >
                                                Λήψη Εικόνας
                                            </Button>
                                            <Button
                                                color="warning"
                                                onClick={() => toggleAnimationForChart(setLineChartByCategoryOptions, lineChartByCategoryOptions)}
                                                size="sm"
                                            >
                                                Εναλλαγή προβολής τιμών
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Line data={chartData.lineChartByCategory} options={lineChartByCategoryOptions} ref={lineChartByCategoryRef} key={JSON.stringify(lineChartByCategoryOptions)}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8 col-md-8">
                                            <h3 className="mb-0">Μηνιαία Κατανομή Παραπόνων ανά Χρόνο πρώτης Απάντησης</h3>
                                        </div>
                                        <div className="col-4 md-4 text-right">
                                            <Button
                                                color="info"
                                                onClick={downloadLineChartByDuration}
                                                size="sm"
                                            >
                                                Λήψη Εικόνας
                                            </Button>
                                            <Button
                                                color="warning"
                                                onClick={() => toggleAnimationForChart(setLineChartByDurationOptions, lineChartByDurationOptions)}
                                                size="sm"
                                            >
                                                Εναλλαγή προβολής τιμών
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Line data={chartData.lineChartByDuration} options={lineChartByDurationOptions} ref={lineChartByDurationRef} key={JSON.stringify(lineChartByDurationOptions)}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            ) : (
                <p>Παρακαλώ Περιμένετε...</p>
            )}
        </div>
    );
}

export default WorkflowBarChart;
