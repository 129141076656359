import React from "react";

import '../../assets/css/datatable.css';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import { MDBDataTable } from 'mdbreact';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      users: [],
      user: []
    };
  }

  toggleUser = (user_id) => {
    if (window.confirm("Είστε σίγουροι ότι θέλετε να πραγματοποιήσετε την συγκεκριμένη ενέργεια;") == true) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/toggleEnabledValue/", {
        method: 'post',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: JSON.stringify({
          UserId: user_id
        })
      })
        .then(res => res.json())
        .then(
          (result) => {

            window.location.reload();

          }
        )
    }


  }

  async componentDidMount() {

    if (localStorage.getItem('token')) {

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            if (result.PersonRole != 'Admin' && result.PersonRole != 'Head User') {
              this.props.history.goBack();
            }
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );

      await fetch(process.env.REACT_APP_API_LINK + "/api/user/all/" + this.state.user.Photo + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.state.users = result;

            var self = this;
            result.forEach(function (part, index) {
              part.Buttons =
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/admin/user/" + part.Id}
                    size="sm"
                  >
                    Επεξεργασία
                  </Button>
                  {part.Enabled == true ?
                    <>
                      <Button
                        color="warning"
                        onClick={() => { self.toggleUser(part.Id) }}
                        size="sm"
                        title="Απενεργοποίηση"
                      >
                        Απενεργοποίηση
                      </Button>
                    </>
                    :
                    <>
                      <Button
                        color="success"
                        onClick={() => { self.toggleUser(part.Id) }}
                        size="sm"
                        title="Ενεργοποίηση"
                      >
                        Ενεργοποίηση
                      </Button>
                    </>}

                </React.Fragment>


            }, result);



            let dataColumns = {
              columns: [
                {
                  label: 'ονοματεπωνυμο ▼',
                  field: 'DisplayName',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'Email ▼',
                  field: 'Email',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Εταιρεια ▼',
                  field: 'Photo',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Κατασταση ▼',
                  field: 'PersonRole',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'Ενεργειες',
                  field: 'Buttons',
                  sort: 'asc',
                  width: 150
                },

              ],
              rows: result,
            };

            this.setState({
              isLoaded: true,
              dataColumns: dataColumns
            });

          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Χρήστες</h3>
                    </div>
                    <div className="col text-right">
                      {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Head User' ?
                        <>
                          <Button
                            color="success"
                            href={"/admin/newuser"}
                            size="sm"
                          >
                            Προσθήκη νέου
                          </Button>
                        </> : <> </>}
                      {this.state.user.PersonRole == 'Admin' ?
                        <>
                          <Button
                            color="warning"
                            href={"/admin/newadmin"}
                            size="sm"
                          >
                            Προσθήκη νέου Admin
                          </Button>
                          <Button
                            color="info"
                            href={"/admin/newcallcenteruser"}
                            size="sm"
                          >
                            Προσθήκη Call-Center Χρήστη
                          </Button>
                        </> : <> </>}
                    </div>
                  </Row>
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Ονοματεπωνυμο</th>
                      <th scope="col">Email</th>
                      <th scope="col">Ρολος</th>
                      <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.map((dynamicData) =>
                      <tr>
                        <td>{dynamicData.DisplayName}</td>
                        <td>{dynamicData.Email}</td>
                        <td>{dynamicData.Roles}</td>
                        <td><Button
                          color="primary"
                          href={"/admin/user/" + dynamicData.Id}
                          size="sm"
                        >
                          Επεξεργασία
                        </Button>
                          {this.state.user.PersonRole == 'Admin' ?
                            <>
                              <Button
                                color="danger"
                                // onClick={() => { this.handleClick(dynamicData.Id) }}
                                size="sm"
                                title="Διαγραφή"
                              >
                                <i className="fas fa-trash" />
                              </Button>
                            </> : <> </>}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table> */}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        responsive
                        bordered
                        exportToCSV
                        hover
                        strip
                        data={this.state.dataColumns}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                      />
                    </div>

                  </Row>
                </CardHeader>
              </Card>
            </Col>

          </Row>
        </Container>
      </>
    );
  }
}

export default Users;
