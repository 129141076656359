import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import { MDBDataTable } from 'mdbreact';

// import endpoint from "./config.js";

class Consumers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      consumers: [],
      user: []
    };
  }

  async componentDidMount() {

    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/consumer/all/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              consumers: result,
            });

            result.forEach(function (part, index) {
              this[index].Buttons =
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/admin/consumer/" + this[index].Id}
                    size="sm"
                  >
                    Αιτήματα Καταναλωτή
                  </Button>

                  {/* {this.state.user.PersonRole == 'Admin' ?
                    <>
                      <Button
                        color="danger"
                        // onClick={() => { this.handleClick(dynamicData.Id) }}
                        size="sm"
                        title="Διαγραφή"
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </> : <> </>} */}
                </React.Fragment>
            }, result);

            this.state.dummy = {
              columns: [
                {
                  label: 'Ονομα Καταναλωτη ▼',
                  field: 'Name',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'Επωνυμο Καταναλωτη ▼',
                  field: 'LastName',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'Email Καταναλωτη ▼',
                  field: 'Email',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'Id Καταναλωτη ▼',
                  field: 'Id',
                  sort: 'asc',
                  width: 100
                },
                // {
                //   label: 'ονομα ▼',
                //   field: 'Name',
                //   sort: 'asc',
                //   width: 100
                // },
                // {
                //   label: 'επιθετο ▼',
                //   field: 'LastName',
                //   sort: 'asc',
                //   width: 150
                // },
                // {
                //   label: 'τηλεφωνο ▼',
                //   field: 'phoneNumber',
                //   sort: 'asc',
                //   width: 150
                // },
                // {
                //   label: 'Email ▼',
                //   field: 'Email',
                //   sort: 'asc',
                //   width: 150
                // },
                {
                  label: 'Ενεργειες',
                  field: 'Buttons',
                  sort: 'asc',
                  width: 150
                },

              ],
              rows: this.state.consumers,
            };
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    };
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Καταναλωτές</h3>
                    </div>
                    <div className="col text-right">

                    </div>
                  </Row>
                </CardHeader>

                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        responsive
                        bordered
                        exportToCSV
                        hover
                        strip
                        data={this.state.dummy}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                      />
                    </div>

                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Consumers;