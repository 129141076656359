import React from "react";
import axios from 'axios';
import Moment from "moment";
import { MDBDataTable } from 'mdbreact';
import { Checkbox } from 'antd';
import Modal from 'react-bootstrap/Modal';
import ReactDatetime from "react-datetime";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Table,
    Container,
    Row,
    Col,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    UncontrolledAlert,
    ModalBody
} from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader.js";

class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: ''
        };

        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    }

    handleChangeStartDate(e) {
        this.props.updateStartDateProp(e);
        this.setState({ startDate: e })
    }
    render() {
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                    inputProps={{
                                        placeholder: "Επιλέξτε Ημερομηνία"
                                    }}
                                    timeFormat={false}
                                    renderDay={(props, currentDate, selectedDate) => {
                                        let classes = props.className;
                                        if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            this.state.startDate._d + "" === currentDate._d + ""
                                        ) {
                                            classes += " start-date";
                                        } else if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            new Date(this.state.startDate._d + "") <
                                            new Date(currentDate._d + "") &&
                                            new Date(this.state.endDate._d + "") >
                                            new Date(currentDate._d + "")
                                        ) {
                                            classes += " middle-date";
                                        } else if (
                                            this.state.endDate &&
                                            this.state.endDate._d + "" === currentDate._d + ""
                                        ) {
                                            classes += " end-date";
                                        }
                                        return (
                                            <td {...props} className={classes}>
                                                {currentDate.date()}
                                            </td>
                                        );
                                    }}
                                    onChange={this.handleChangeStartDate}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }
}

class Holidays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            isLoaded: false,
            holidays: [],
            user: [],
            dummy: [],
            holidayYears: [],
            showModalThesmiko: false,
            showModalNewHoliday: false,
            showModalNewYear: false,
            newHolidayYear: null,
            isOpenDanger: false,
            isOpenDangerForSameYear: false
        };
        this.deleteHoliday = this.deleteHoliday.bind(this)
    }


    deleteHoliday(holidayId) {
        if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε την συγκεκριμένη αργία;") == true) {
            if (localStorage.getItem('token')) {
                axios.delete(process.env.REACT_APP_API_LINK + '/api/holiday/delete/' + holidayId + "/", {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                })
                    .then(res => {
                        window.location.reload(false);
                    })
            }
        }
        else { }
    }

    setShowThesmiko = () => {
        this.setState({ showModalThesmiko: !this.state.showModalThesmiko });
    }

    setShowNewHoliday = () => {
        this.setState({ showModalNewHoliday: !this.state.showModalNewHoliday });
    }

    setShowNewYear = () => {
        this.setState({ showModalNewYear: !this.state.showModalNewYear });
    }

    async componentDidMount() {
        if (localStorage.getItem('token')) {

            fetch(process.env.REACT_APP_API_LINK + "/api/holiday/years/all/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            holidayYears: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )

            fetch(process.env.REACT_APP_API_LINK + "/api/holiday/all/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            holidays: result,
                        });
                        result.forEach(function (part, index) {
                            part.HolidayDate = Moment(part.HolidayDate).locale('el').format('DD MMM YYYY')
                            part.DateCreated = Moment(part.DateCreated).locale('el').format('DD MMM YYYY HH:mm')
                            part.Buttons =
                                <React.Fragment>
                                    <Button
                                        color="danger"
                                        onClick={() => { this.deleteHoliday(part.Id) }}
                                        size="sm"
                                        title="Διαγραφή"
                                    >
                                        <i className="fas fa-trash" />
                                    </Button>
                                </React.Fragment>
                        }, this);


                        this.state.dummy = {
                            columns: [
                                {
                                    label: 'Ημερομηνία αργίας ▼',
                                    field: 'HolidayDate',
                                    sort: 'asc',
                                    width: 100
                                },
                                {
                                    label: 'Περιγραφή αργίας ▼',
                                    field: 'HolidayDescription',
                                    sort: 'asc',
                                    width: 100
                                },
                                {
                                    label: 'Προστέθηκε από ▼',
                                    field: 'Username',
                                    sort: 'asc',
                                    width: 150
                                },
                                {
                                    label: 'Τελευταία τροποποίηση στις ▼',
                                    field: 'DateCreated',
                                    sort: 'asc',
                                    width: 150
                                },
                                {
                                    label: 'Ενεργειες',
                                    field: 'Buttons',
                                    sort: 'asc',
                                    width: 150
                                },

                            ],
                            rows: result,
                        };
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            user: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );


        };
    }
    createHolidayYear = (holidayYear) => {
        if (localStorage.getItem('token')) {
            if (window.confirm("Είστε σίγουροι ότι θέλετε να πραγματοποιήσετε την συγκεκριμένη ενέργεια;") == true) {
                fetch(process.env.REACT_APP_API_LINK + '/api/holiday/newYear/' + this.state.newHolidayYear + '/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    })
                })
                    .then(result => {
                        console.log(result);
                        if (result.status == 404) {
                            this.setState({
                                isOpenDanger: true,
                                loadingCharts: false
                            });
                        }
                        else if (result.status == 403) {
                            this.setState({
                                isOpenDangerForSameYear: true,
                                loadingCharts: false
                            });
                        }
                        else {
                            window.location.replace("/admin/holidays");
                        }
                    },
                        (error) => {
                        }
                    )
            } else { }
        }
    }

    updateYear(evt) {
        this.setState({
            newHolidayYear: evt.target.value
        })
    }

    onDismissDanger = () => {
        this.setState({
            isOpenDanger: false,
            isOpenDangerForSameYear: false
        });
    }

    updateStartDate = (startdate) => {
        try {

            this.setState({ startDate: startdate.toDate().toLocaleString('en-CA').split(',')[0] });
        }
        catch
        {
            this.setState({ startDate: '-' });
        }
    }



    createHoliday = (holidayId) => {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/holiday/new/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'HolidayDate': this.state.startDate,
                    'Username': this.state.user.DisplayName,
                    'UserId': this.state.user.Id,
                    'HolidayDescription': this.state.description
                })
            })
                .then(data => {
                    window.location.reload(false);
                })
        }
    }

    updateHoliday(evt) {
        this.setState({
            date: evt.target.value
        })
    }

    updateDescription(evt) {
        this.setState({
            description: evt.target.value
        })
    }

    _onChange = (e, year) => {
        let newValue = !!e.target.checked;
        let years = this.state.holidayYears.filter(x => x.Year === year)[0];
        if (!years) {
            years = { Year: year }
            this.state.holidayYears.push(years)
        }
        years.Checked = newValue;
        this.setState({
            holidayYears: this.state.holidayYears,
        });
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/holiday/years/new/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'Year': e.target.name,
                    'Username': this.state.user.DisplayName,
                    'UserId': this.state.user.Id,
                    'Checked': e.target.checked
                })
            })
                .then(data => {
                })
        }
    };

    getAvailableYears() {
        let currentYear = new Date().getFullYear();
        let results = [];
        for (let i = -3; i < 10; i++) {
            results.push(currentYear + i);
        }
        return results;
    }

    CustomCheckBox({ self, year, onChange }) {
        let currentYear = new Date().getFullYear();
        let item = self.state.holidayYears.filter(x => x.Year == year)[0] ?? null;
        let isCurrentYear = year === currentYear;
        let isReadonly = year < currentYear;

        return (
            <>
                {
                    isCurrentYear ? <b>{year}</b> : <>{year}</>
                }
                <Checkbox
                    className='tool-theme-checkbox'
                    name={year}
                    checked={item?.Checked ?? false}
                    onChange={(e) => onChange(e, year)}
                    style={{ marginLeft: "15px", marginRight: "15px" }}
                    disabled={true}
                >
                </Checkbox>

                <><i> Ενημέρωση από</i> : {item?.Username ?? " - "}</>
            </>
        );
    };


    render() {
        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--8" fluid>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Αργίες</h3>
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>
                                            {this.state.user.PersonRole == 'Admin' &&
                                                <>
                                                    <Button
                                                        color="info"
                                                        size="sm"
                                                        onClick={this.setShowThesmiko}
                                                    >
                                                        Πληροφορίες
                                                    </Button>
                                                </>}
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>
                                            {this.state.user.PersonRole == 'Admin' &&
                                                <>
                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        onClick={this.setShowNewHoliday}
                                                    >
                                                        Προσθήκη νέας Αργίας
                                                    </Button>
                                                </>}
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>
                                            {this.state.user.PersonRole == 'Admin' &&
                                                <>
                                                    <Button
                                                        color="warning"
                                                        size="sm"
                                                        onClick={this.setShowNewYear}
                                                    >
                                                        Προσθήκη νέου έτους
                                                    </Button>
                                                </>}
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <MDBDataTable
                                                bordered
                                                exportToCSV
                                                hover
                                                responsive
                                                strip
                                                data={this.state.dummy}
                                                id="mdbdttable"
                                                entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                noDataComponent="Δεν υπάρχουν δεδομένα"
                                                searchLabel="Αναζήτηση"
                                                paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                entriesLabel={'Εμφάνιση Αργιών ανά: '}
                                            />
                                        </div>

                                    </Row>
                                    <Row>


                                        <Col md={5}>
                                            <CardHeader className="border-0">
                                                <h3>Συμπληρωμένα έτη με αργίες</h3>
                                                {this.getAvailableYears().map((year) => {
                                                    return (
                                                        <div>
                                                            <this.CustomCheckBox
                                                                self={this}
                                                                year={year}
                                                                onChange={(e) => this._onChange(e, year)}
                                                            />
                                                        </div>)
                                                })}
                                            </CardHeader>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Modal
                            show={this.state.showModalThesmiko}
                            onHide={this.setShowThesmiko}
                            //dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            style={{ opacity: 1, minWidth: "80vw" }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    <strong style={{ fontSize: '18px', textAlign: 'center' }}>Θεσμικό Πλαίσιο </strong>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ maxHeight: '80vh', overflowY: "auto" }}>
                                <p >	<strong>ΠΡΟΣΔΙΟΔΙΣΜΟΣ ΕΠΙΣΗΜΩΝ ΑΡΓΙΩΝ ΓΙΑ ΤΗ ΛΕΙΤΟΥΡΓΙΑ ΤΟΥ MYRAE</strong>
                                    <br />
                                    <br />
                                    Πέραν των ημερών Σαββάτου και Κυριακής, για τον προσδιορισμό των επισημων αργιών που ορίζονται  στην εφαρμογή myrae προκειμένου να υπολογίζεται, όπου χρειαστεί, ο αριθμός εργάσιμων ημερών, εφαρμόστικαν τα προβλεπόμενα του Άρθρου 60 της από 3/9/2021 εφαρμοστικής εγκυκλίου του υπουργείου Εργασίας και Κοινωνικών Υποθέσεων όπου παρέχονται Οδηγίες για την εφαρμογή του Κεφαλαίου Α΄ «ΡΥΘΜΙΣΕΙΣ ΑΤΟΜΙΚΟΥ ΕΡΓΑΤΙΚΟΥ ΔΙΚΑΙΟΥ» (άρθρα 55-67) του μέρους IV του Ν. 4808/2021 (Α’101)
                                    <br />
                                    <br />
                                    Σύμφωνα με την από 3/9/2021 εφαρμοστική εγκύκλιο που εξέδωσε το υπουργείο Εργασίας και Κοινωνικών Υποθέσεων για το νέο πλαίσιο που διέπει υπερωρίες, διευθέτηση του χρόνου εργασίας, διαλείμματα, αργίες, προστασία από τις απολύσεις τίθενται σε εφαρμογή οι νέες ρυθμίσεις του νόμου 4808/2021 για την Προστασία της Εργασίας αναφορικά με το χρόνο εργασίας, τις υπερωρίες, τη δυνατότητα τετραήμερης εργασίας μετά από αίτηση του εργαζόμενου στο πλαίσιο της διευθέτησης του χρόνου εργασίας, τα διαλείμματα αλλά και την ρητή κατοχύρωση του 8ωρου / 40ωρου.
                                    <br />
                                    <a href="https://ypergasias.gov.gr/ekdothike-i-efarmostiki-egkyklios-gia-to-neo-plaisio-pou-diepei-yperories-diefthetisi-tou-chronou-ergasias-dialeimmata-argies-prostasia-apo-tis-apolyseis/">https://ypergasias.gov.gr/ekdothike-i-efarmostiki-egkyklios-gia-to-neo-plaisio-pou-diepei-yperories-diefthetisi-tou-chronou-ergasias-dialeimmata-argies-prostasia-apo-tis-apolyseis/</a>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <strong>Άρθρο 60 – Αργία</strong>
                                    <br />
                                    <br />
                                    Με τη διάταξη της παραγράφου 1 του άρθρου 60 ενσωματώνονται σε μια ενιαία ρύθμιση οι ημέρες υποχρεωτικής αργίας για όλες τις επιχειρήσεις, εκμεταλλεύσεις και εργασίες γενικά, οι οποίες αργούν κατά τις Κυριακές και τις ημέρες αργίας. Συγκεκριμένα, ως ημέρες υποχρεωτικής αργίας ορίζονται πλέον οι εξής:
                                    <br />
                                    α) Η 1η Ιανουαρίου, β) η εορτή των Θεοφανείων (6η Ιανουαρίου), γ) η 25η Μαρτίου, δ) η Δευτέρα του Πάσχα, ε) η 1η Μαΐου, στ) η εορτή της Κοιμήσεως της Θεοτόκου (15η Αυγούστου), ζ) η 28η Οκτωβρίου, η) η εορτή της Γεννήσεως του Χριστού (25η Δεκεμβρίου) και θ) η 26η Δεκεμβρίου.
                                    <br />
                                    Όπως γίνεται εμφανές με την παρούσα διάταξη, προστίθενται στις ήδη υφιστάμενες ημέρες υποχρεωτικής αργίας και δύο επιπλέον ημέρες ήτοι η 1η Ιανουαρίου και η εορτή των Θεοφανείων (6η Ιανουαρίου).
                                    <br />
                                    Περαιτέρω, με την παράγραφο 2 του ιδίου άρθρου, προβλέπεται η δυνατότητα καθορισμού, με αποφάσεις του Υπουργού Εργασίας και Κοινωνικών Υποθέσεων, που εκδίδονται μετά από γνωμοδότηση του Ανώτατου Συμβουλίου Εργασίας (Α.Σ.Ε.) και δημοσιεύονται στην Εφημερίδα της Κυβερνήσεως, και άλλων εορτών, μέχρι πέντε (5) κατ’ έτος, ως ημερών υποχρεωτικής ή προαιρετικής αργίας για το σύνολο της Χώρας. Επιπλέον, με την ίδια ρύθμιση δίδεται η δυνατότητα στους Περιφερειάρχες, με αποφάσεις τους (χωρίς γνωμοδότηση του Α.Σ.Ε.), να καθορίζουν και άλλες ημέρες ως τοπικές αργίες.
                                    <br />
                                    Τέλος, με την ίδια διαδικασία, δύναται να καταργούνται ή να αλλάζουν οι προβλεπόμενες αργίες σε εθνικό (με απόφαση Υπουργού και γνωμοδότηση ΑΣΕ) ή σε τοπικό επίπεδο (με απόφαση Περιφερειάρχη που δεν απαιτεί γνωμοδότηση του ΑΣΕ).
                                    <br />
                                    <br />
                                    Οι ημέρες της Καθαράς Δευτέρας και Μεγάλης Παρασκευής, δεν περιλαμβάνονται στις επίσημες αργίες που ορίζει ο νόμος για τους εργαζόμενους στον ιδιωτικό τομέα,  οι ιδιωτικές επιχειρήσεις αργούν στην περίπτωση που η Καθαρά Δευτέρα ή η Μεγάλη Παρασκευή έχει χαρακτηρισθεί αργία από διάταξη ΣΣΕ, Κανονισμού Εργασίας της επιχείρησης, από επιχειρησιακή συνήθεια και έθιμο, κατά το οποίο η επιχείρηση παραμένει κλειστή την ημέρα αυτή. Με βάση τα άνωθι, και με σκοπό την ομοιόμορφη αντιμετώπιση του συνόλου των χρηστών του my.rae.gr οι εν λόγω ημέρες λογίζονται επίσης ως αργίες (δεν προσμετρώνται ως εργάσιμες ημέρες).<br />
                                    ΣΧΕΤΙΚΑ: Κέντρο Πληροφόρησης Εργαζομένων & Ανέργων της ΓΣΕΕ<br />
                                    <a href="https://www.kepea.gr/pos-amoivetai-i-kathara-deytera">https://www.kepea.gr/pos-amoivetai-i-kathara-deytera</a>
                                    <br />
                                    <a href="https://www.kepea.gr/aarticle.php?id=561">https://www.kepea.gr/aarticle.php?id=561</a></p>
                            </Modal.Body>
                        </Modal>
                        <Modal

                            show={this.state.showModalNewHoliday}
                            onHide={this.setShowNewHoliday}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            style={{ opacity: 1 }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    <strong style={{ fontSize: '18px', textAlign: 'center' }}>Προσθήκη νέας Αργίας </strong>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <div className="pl-lg-12">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Datepicker updateStartDateProp={this.updateStartDate} />
                                            </Col>
                                            <Col xs={12} md={12}>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-description"
                                                    placeholder="Προσθέστε μια Περιγραφή"
                                                    type="holiday-description"
                                                    onChange={evt => this.updateDescription(evt)}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    color="primary"
                                    onClick={() => { this.createHoliday() }}
                                    size="sm"
                                >
                                    Προσθήκη
                                </Button>

                            </Modal.Footer>
                        </Modal>
                        <Modal

                            show={this.state.showModalNewYear}
                            onHide={this.setShowNewYear}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            style={{ opacity: 1 }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    <strong style={{ fontSize: '18px', textAlign: 'center' }}>Προσθήκη νέου έτους</strong>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div class="__modal-body--header">
                                    <UncontrolledAlert color="danger" toggle={this.onDismissDanger} isOpen={this.state.isOpenDanger} fade={false}>
                                        <span className="alert-inner--text">
                                            Το έτος που συμπληρώσατε είναι εκτός ορίων!
                                        </span>
                                    </UncontrolledAlert>
                                </div>
                                <div class="__modal-body--header">
                                    <UncontrolledAlert color="danger" toggle={this.onDismissDanger} isOpen={this.state.isOpenDangerForSameYear} fade={false}>
                                        <span className="alert-inner--text">
                                            Το έτος που συμπληρώσατε είναι ήδη καταχωρημένο!
                                        </span>
                                    </UncontrolledAlert>
                                </div>
                                <div class="__modal-body--content">
                                    <Form>
                                        <div className="pl-lg-12">
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-holiday-year"
                                                        type="number"
                                                        placeholder="Συμπληρώστε το έτος"
                                                        onChange={evt => this.updateYear(evt)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    color="primary"
                                    onClick={() => { this.createHolidayYear() }}
                                    size="sm"
                                >
                                    Προσθήκη
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Holidays;
