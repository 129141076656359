import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from 'react-loadingg';

import { MDBDataTable } from 'mdbreact';

import Moment from "moment";
import 'moment/locale/el';

import axios from 'axios';

// import endpoint from "./config.js";

class Consumer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      id: '',
      fullName: '',
      email: '',
      instances: [],
      applications: [],
      // name: '',
      // lastname: '',
      // email: '',
      // phone: '',
      user: []
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/consumer/details/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              id: result.Id,
              fullName: result.Name + ' ' + result.LastName,
              email: result.Email
              // name: result.Name,
              // lastname: result.LastName,
              // email: result.Email,
              // phone: result.phoneNumber
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      fetch(process.env.REACT_APP_API_LINK + "/api/consumer/instances/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              loading: false,
              instances: result
            });

            result.forEach(function (part, index) {
              this[index].Status = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
              this[index].Buttons =
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/admin/details/" + this[index].Id}
                    size="sm"
                    title="Επεξεργασία"
                  >
                    <i className="fas fa-eye" />
                  </Button>

                  {/* {this.state.user.PersonRole == 'Admin' ?
                    <>
                      <Button
                        color="danger"
                        // onClick={() => { this.handleClick(dynamicData.Id) }}
                        size="sm"
                        title="Διαγραφή"
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </> : <> </>} */}
                </React.Fragment>
            }, result);

            this.state.dummy = {
              columns: [
                {
                  label: 'Α/Α ▼',
                  field: 'SerialNumber',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'κατασταση ▼',
                  field: 'Status',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Ενεργειες',
                  field: 'Buttons',
                  sort: 'asc',
                  width: 150
                },

              ],
              rows: this.state.instances,
            };
          },
          (error) => {
            this.setState({
              loading: false,
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    };
  }

  // resetPassword = consumerId => {
  //   if (localStorage.getItem('token')) {
  //     fetch(process.env.REACT_APP_API_LINK + '/api/auth/resetpassword/' + consumerId + '/', {
  //       method: 'PUT',
  //       headers: new Headers({
  //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
  //       })
  //     })
  //       .then(res => res.json())
  //       .then(data => {
  //         // console.log(data);
  //         alert(data);
  //         // this.setState({
  //         //     data_statsbyuserkind: data
  //         // })

  //       })
  //   }
  // }

  // saveChanges = consumerId => {
  //   if (localStorage.getItem('token')) {
  //     axios.put(process.env.REACT_APP_API_LINK + '/api/consumer/update/', {
  //       data: { 'Name': this.state.name, "LastName": this.state.lastname, 'Email': this.state.email, 'phoneNumber': this.state.phone },
  //       headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  //     })
  //       .then(res => {
  //         // console.log(res.data);
  //       })
  //   }
  // }

  // updateName(evt) {
  //   // console.log(evt.target.value);
  //   this.setState({
  //     name: evt.target.value
  //   })
  // }

  // updateLastName(evt) {
  //   // console.log(evt.target.value);
  //   this.setState({
  //     lastname: evt.target.value
  //   })
  // }

  // updateEmail(evt) {
  //   // console.log(evt.target.value);
  //   this.setState({
  //     email: evt.target.value
  //   })
  // }

  // updatePhone(evt) {
  //   // console.log(evt.target.value);
  //   this.setState({
  //     phone: evt.target.value
  //   })
  // }

  //   deleteApplication = applicationId => {
  //     if (localStorage.getItem('token')) { 
  //       axios.delete(process.env.REACT_APP_API_LINK + '/api/applications/' + applicationId + '/', {
  //         headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  //       })
  //       .then(res => {
  //         window.location.reload(false);
  //       })
  //     }
  //   }

  deleteInstance = instanceId => {
    if (localStorage.getItem('token')) {
      axios.delete(process.env.REACT_APP_API_LINK + '/api/workflowinstances/' + instanceId + '/', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          window.location.reload(false);
        })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <GenericHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Καταναλωτή</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* {this.state.user.PersonRole == 'Admin' ?
                          <>
                            <Button
                              color="primary"
                              href="#pablo"
                              onClick={() => { this.saveChanges(this.state.details.Id) }}
                              size="sm"
                            >
                              Αποθήκευση αλλαγών
                            </Button>
                          </> : <> </>} */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fullname"
                              >
                                Ονοματεπώνυμο Καταναλωτή
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.fullName}
                                id="input-fullname"
                                placeholder="fullName"
                                type="text"
                                disabled={true}
                              // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email Καταναλωτή
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.email}
                                id="input-email"
                                placeholder="Id"
                                type="text"
                                disabled={true}
                              // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Id Καταναλωτή
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.id}
                                id="input-username"
                                placeholder="Id"
                                type="text"
                                disabled={true}
                              // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Όνομα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.Name}
                                id="input-username"
                                placeholder="Όνομα"
                                type="text"
                                onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col> */}
                          {/* <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Επίθετο
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.LastName}
                                id="input-email"
                                placeholder="Επίθετο"
                                type="email"
                                onChange={evt => this.updateLastName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.details.Email}
                                placeholder="Email"
                                type="email"
                                onChange={evt => this.updateEmail(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Τηλέφωνο
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.details.phoneNumber}
                                placeholder="Τηλέφωνο"
                                type="email"
                                onChange={evt => this.updatePhone(evt)}
                              />
                            </FormGroup>
                          </Col> */}
                        </Row>
                      </div>

                      {/* {this.state.user.PersonRole == 'Admin' ?
                        <>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Επαναφορα κωδικου προσβασης
                          </h6>

                          <div className="pl-lg-4">
                            <Row>
                              <Col md="4" style={{ alignTextAlign: "center" }}>
                                <Button
                                  color="primary"
                                  href="#pablo"
                                  onClick={() => { this.resetPassword(this.state.details.Id) }}
                                  size="sm"
                                >
                                  Επαναφορά κωδικού
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </> : <> </>} */}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Υποβεβλημένες αιτήσεις καταναλωτή</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                          <>
                            <Button
                                color="success"
                                href="/admin/newinstance"
                                size="sm"
                            >
                                Προσθήκη νέας αίτησης
                            </Button>
                          </> : <> </>} */}
                      </Col>
                    </Row>

                  </CardHeader>

                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <MDBDataTable
                          responsive
                          bordered
                          exportToCSV
                          hover
                          strip
                          data={this.state.dummy}
                          id="mdbdttable"
                          entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                          noDataComponent="Δεν υπάρχουν δεδομένα"
                          searchLabel="Αναζήτηση"
                          paginationLabel={['Προηγούμενο', 'Επόμενο']}
                          infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                          entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                        />
                      </div>

                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            {/* <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Έγγραφα καταναλωτή</h3>
                      </Col>
                    </Row>

                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Ονομα αρχειου</th>
                        <th scope="col">Ημερομηνια Αναρτησης</th>
                        <th scope="col">Ενεργειες</th>
                      </tr>
                    </thead>
                  </Table>
                </Card>
              </Col>
            </Row> */}


          </Container>
        </>
      );
    }
  }
}

export default Consumer;